<template>
  <div class="menu">
    <!-- {{menu}} -->
    <div class="top-menu">

      <div class="left">
        <a href="/" style="display: flex;">
          <div style="margin-right: 20px;">
            <div style="    width: 40px;
    height: 40px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    margin: 0 auto;
    margin-top:10px
   ">
              <img class="logo" src="../../assets/images/logo.png"/>
            </div>

             <span class="logoTitle">丰源智控</span>
          </div>

        <span>丰源水在线智控系统</span>
        <!-- <span class="sub">/</span>
        <span class="sub">水在线平台</span> -->
        </a>
      </div>

      <div class="right">
        <span style="font-size: 26px; padding-right: 10px">
          {{ weather }}</span><i style="font-size: 22px; padding-right: 10px"> /</i>
        <span style="padding-right: 30px">{{ currentTime }}</span>
        <span class="hello"><img src="@/assets/images/avatar.svg" /> 您好！</span>
          <span class="name" @click="changePasswordToogle()">{{userName}}</span>
        <a class="logout" href="#" @click="logout()"> <i class="iconfont icon-tuichu"></i>退出</a>

       <div class="fullScreen">
        <!-- 	<span class="bgSpan" v-if="!showFullScreen" @click="showFullScreenFun" title="全屏">
        		<i class="iconfont icon-quanping "></i>
        	</span>
        	<span class="bgSpan" v-else @click="showFullScreenFun" title="退出全屏">
        			<i class="iconfont icon-tuichuquanping "></i>
        	</span> -->
        </div>
      </div>
    </div>
    <div class="bottom-menu">
       <top-menu-item v-for="(item, index) in menu" :key="item.rightId"  :icon="item.icon" :menuItemActive="index === activationIndex ? true: false" @click.native="leftMenuId(index, item.path)">
         {{item.rightName}}
         </top-menu-item >
    </div>
    <el-dialog title="修改密码" :visible.sync="updatePassDialogFormVisible" width="20%">
      <el-form :model="passForm"  :rules="rules" ref="ruleForm">
        <el-form-item label="用户名" label-width="80px">
          <el-input v-model="passForm.userName" size="mini"  :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="旧 密 码" label-width="80px" prop="oldPassword">
          <el-input type="password" v-model="passForm.oldPassword" autocomplete="new-password"  size="mini"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="新 密 码" label-width="80px" prop="password">
          <el-input  type="password" v-model="passForm.password"  autocomplete="new-password" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="确定密码" label-width="80px" prop="repeatPassword">
          <el-input type="password"  v-model="passForm.repeatPassword" autocomplete="new-password"  size="mini"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="updatePassDialogFormVisible = false"  size="mini">取 消</el-button>
        <el-button type="primary" @click="changePassword()"  size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import mixin from "./fullscreen"
import TopMenuItem from './TopMenuItem.vue'
import '@/utils/global'
import { getStorage, setStorage } from '@/utils/storage'
import { changePassword } from '@/ApI/index'
import DayRecord from '@/views/Analysis/DayRecord.vue'
import axios from 'axios'
export default {
  // mixins:[mixin],

  components: { TopMenuItem, DayRecord },
  data () {
    let path = this.$route.path.split('/')
    path = path[1]
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
        currentTime: this.dayjs().format("YYYY/MM/DD HH:mm:ss"),
         weather: "",
         timer: null, //定时器
      path,
      menu: [],
      activePath:'',
      icon: ['icon-qihouxitongjiancezhishu', 'icon-guanlipingtaiicon_zichanguanli', 'icon-jingji', 'icon-icon-GIS_yunhangguanli', 'icon-jichuguanli', 'icon-tongzhi5', 'icon-xitongguanli_jiaoseguanli'],
      activationIndex: '',
      userName: '用户',
      updatePassDialogFormVisible: false,
      passForm: {
        userName: '用户',
        oldPassword: '',
        password: '',
        repeatPassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        repeatPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      UserMenusListToButton: '' // 全部权限内容，目的是获取按钮
    }
  },
  watch: {

    activationIndex: {
      deep: true,
      handler (newV, oldV) {
        this.$parent.leftMenuId = this.activationIndex
        if (!newV && newV !== 0) { // 如果丢失从记录里查找
          this.activationIndex = getStorage(this.$storageKey.leftMenuId)
          this.$parent.leftMenuId = this.activationIndex
        }
      }
    }
  },
  mounted () {

    //天气
    axios.get(
        'https://yiketianqi.com/free/day?unescape=1&appid=19897462&appsecret=o23JogiW&lng=121.454094&lat=31.160065')
      .then(
        res => {
          var tem=(!res.data.tem&& res.data.tem!=0)?'--':res.data.tem;
          var wea=!res.data.wea?'--':res.data.wea
          this.weather =tem + '℃' + ' ' + wea
        }
      )
      this.timer = setInterval(() => {
        this.currentTime = this.dayjs().format("YYYY/MM/DD HH:mm:ss");
      }, 1000);
    if (!this.activationIndex) {
      this.activationIndex = getStorage(this.$storageKey.leftMenuId) // 一级菜单状态丢失后从记录中获取
    }
    // 获取导航
    let menuList = getStorage(this.$storageKey.UserMenusList)
   if(!menuList){
      menuList = []
    }
    if(menuList.length>0){
      menuList = menuList.filter((obj)=>{
        return (obj.platformType == null ||  obj.platformType == 1) ;//因为原先旧的菜单没有去编辑platformType是null  不是1所以NULL的情况也要考虑到
      })
    }
    this.UserMenusListToButton = JSON.stringify(getStorage(this.$storageKey.UserMenusList))
    setStorage(this.$storageKey.allButton, this.UserMenusListToButton)
    // 导航排序
    // menuList.sort((a, b) => { return a.order - b.order })  后端已经排序了
    // 生成目录的url
    menuList.forEach((item, index) => {
      let newArr = [] // 选中的
      newArr = []
      // this.checkedList(item.path, menuList, newArr)
      // if (newArr[0]) {
      //   menuList[index].path = newArr[0]
      // } else {
      //   menuList[index].path = newArr[1]
      // }
      // menuList[index].path4 =
      // menuList[index].path =
      this.checkedList(item.children, newArr)
      menuList[index].path = newArr[0]
    })
    // 生成一级导航数据
    let menuListIndex = -1

    menuList.forEach((item, index) => {
      if (item.pid === 0 && item.rightType === 1 && item.rightName.indexOf('0')==-1) {
        menuListIndex++
         this.menu.push(item)
        if (item.path && item.path.includes(this.path)) {
          this.$parent.leftMenuId = menuListIndex
          // 第几个一级目录（用来确定二级目录）
          setStorage(this.$storageKey.leftMenuId, menuListIndex)
        }
      }
    })
    this.activePath=this.$route.matched[0].path;
    this.$parent.topMenu = this.menu;

    // 获取用户名
    this.userName = getStorage(this.$storageKey.UserInfo)
    this.passForm.userName = this.userName.trim()
    // 驾驶舱清空一级菜单高亮
    if (this.$route.fullPath === '/Cockpit/Index') {
      this.activationIndex = ''
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    changePasswordToogle(){
      this.updatePassDialogFormVisible = true
      this.$set(this.passForm, "oldPassword", "");
      this.$set(this.passForm, "password", "");
      this.$set(this.passForm, "repeatPassword", "");
    },
    // 一级菜单点击事件
    leftMenuId (index, path) {


      this.$parent.leftMenuId = index // 第几个一级菜单下的二级菜单
      setStorage(this.$storageKey.leftMenuId, index)
      this.activationIndex = index // 一级菜单高亮
      this.$router.push(path)
         this.activePath=this.$route.matched[0].path;
    },
    logout () {
      localStorage.clear()
      this.$message.success('您已退出登录')
      this.$router.push('/login')
    },
    async changePassword () {
      const { data: res } = await changePassword(this.passForm)
      if (res.success) {
        this.$message.success(res.message)
        this.updatePassDialogFormVisible = false
        this.$set(this.passForm, "oldPassword", "");
        this.$set(this.passForm, "password", "");
        this.$set(this.passForm, "repeatPassword", "");
        setTimeout(()=>{
          this.logout();
          },1000)

      } else {
        this.$message.error(res.message)
      }
    },
    // 查出目录url  目前已经不用这个了
    checkedList0 (path, data, newArr) {
      data.forEach(item => {
        if (item.rightType === 2) {
          newArr[1] = item.path
        }
        if (item.path === path && item.rightType === 2) {
          // if (item.children.length === 0) { // item.children  &&
          // newArr.push(item.path)
          // }
          newArr[0] = path
        } else {
          if (item.children !== null && item.children.length !== 0 && item.rightType === 1) {
            this.checkedList(path, item.children, newArr)
          }
        }
      })
    },
    // 查出目录url
    checkedList (data, newArr) {
      data.forEach(item => {
        if (item.rightType === 2) {
          newArr.push(item.path)
        } else {
          if (item.children !== null && item.children.length !== 0 && item.rightType === 1) {
            this.checkedList(item.children, newArr)
          }
        }
      })
    }
  }
}
</script>

<style  lang="scss" scoped>
.menu {
  .top-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: 80px;
    min-height: 60px;
    background: url("~@/assets/top-bg.png");
    background-size: 100% 100%;
    // padding-left: .1927rem;
    // position: relative;
    box-sizing: border-box;

    .left {
      padding-left: 20px;

      font-size: 30px;
      font-weight: 400;
      // font-style: italic;
      color: #fefeff;
      line-height: 80px;
      height: 100%;
      display: inline-block;

      img{

        width: auto;
        height: 40px;
        vertical-align: top;

      }
      .logoTitle{
        font-size: 12px;
        color: #fff;
        display: block;
        height: 20px;
        line-height: 20px;
        margin-top:4px;
      }
    }

    .sub {
      width: 87px;
      height: 16px;
      font-size: 15px;
      font-weight: 400;
      color: #8fbbdd;
      line-height: 44px;
    }

    .middle {
      flex: 1;
      width: 6.7rem; // 5.7292rem;
      font-size: 13px;
      min-width: 840px;
      display: flex;
      justify-content: center;
      .menu-item{
        width: 100%;
        max-height: .276rem;
        min-height: 0.1rem;
        flex: 1;
        overflow: hidden;
       ::v-deep .text {
            padding: 0 20px;
           ::v-deep i {
                font-size: .0885rem;
                margin-right: 0px !important;
            }
        }
      }
        // .containerActive{
        //   .containerActive{
        //     color: #fff;
        //     background: radial-gradient(#1d57bf 40%, #1394d0 94%) !important;
        //   }
        // }
    }

    .right {
      font-size: 13px;
      font-weight: 400;
      color: #10e7e6;
      line-height: 80px;
      min-width: 258px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      .logout {
        color: #fff;

        i {
          margin-right: 4px;
        }
      }
      .hello {
        font-weight: 400;
        margin: 0;
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
          margin-right: 2px;
        }
      }
      .name {
          color: #ffffff;
          cursor:pointer;
          margin-right: 20px;
        }
    }
  }
}
.bottom-menu{
  width: 100%;
  padding: 10px 20px;
  text-align: left;
  border-bottom: 1px solid #1a7fbd;
  background-color: #142b85;
}
.fullScreen {
	margin: 0 20px;
	cursor: pointer;
  line-height: 80px;
}
.bgSpan {
  margin-top: 25px;
	display: block;
	width: 30px;
	height: 30px;
	/* background: rgba(223,91,91,0.5); */
	text-align: center;
	line-height: 30px;
	border-radius: 50%;
	box-shadow: 0 0 10px;
	i {
		font-size: 15px;
		color: #fff;
	}
}

.fullScreen:hover .bgSpan {
	/* transform: scale(1.2); */
	background: rgba(23, 52, 120, 0.5);
}
</style>
