import request from '@/utils/Api'
/**
 * 下载导入模板
 */
export const getImport = () => {
  return request({
    url: '/api/archive/content/excel/template/水在线-档案导入模板.xlsx',
    method: 'get'
  })
}
/**
 * 小区档案列表
 */
export const getVillageList = (data) => {
  return request({
    url: '/api/archive/village/getVillageList',
    method: 'post',
    data: data
  })
}
/**
 * 添加档案
 */
export const addVillage = (data) => {
  return request({
    url: '/api/archive/village/addVillage',
    method: 'post',
    data: data
  })
}
/**
 * 编辑档案
 */
export const editVillage = (data) => {
  return request({
    url: '/api/archive/village/editVillage',
    method: 'post',
    data: data
  })
}
/**
 * 导出小区档案
 */
export const getVillageExportData = (data) => {
  return request({
    url: '/api/archive/village/getVillageExportData',
    method: 'post',
    data: data
  })
}
// 删除
export const deleteVillage = (villageId) => {
  return request({
    url: '/api/archive/village/deleteVillage',
    method: 'get',
    params: {
      villageId
    }
  })
}

/**
 * 未绑定水表list
 * villageId      integer     小区ID
    pageSize      string      页大小
    pageIndex     string       页码
 */
export const getNoBindMeterList = (data) => {
  return request({
    url: '/api/archive/village/getNoBindMeterList',
    method: 'post',
    data: data
  })
}

/**
 * 业主绑定/解绑水表
 * type         string        绑定类型        1绑定 2解绑
meterId         integer         水表ID
ownerId         integer         业主ID
 */
export const bindOwnerMeter = (data) => {
  return request({
    url: '/api/archive/village/bindOwnerMeter',
    method: 'post',
    data: data
  })
}
/**
 * 右侧村级区域联动下拉列表
 * type         string        查询类型        1片区 2小区
organId         integer         类型关联Id
 */
export const getVillageSelectListItemList = (data) => request({
  url: '/api/archive/village/getVillageSelectListItemList',
  method: 'post',
  data
})

//给井添加位置 http://192.168.20.104:5038/api/archive/Village/EditWellPosition

export const EditWellPosition = (data) => request({
  url: '/api/archive/Village/EditWellPosition',
  method: 'post',
  data
})


export const TransferVillage = (data) => request({
  url: '/api/archive/Village/TransferVillage',
  method: 'post',
 data
})
