<template>
  <div class="box-desktop">
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-1">
        <img src="../assets/images/desktop/jiashicang.png" alt="" />
      </div>
      <div class="icon-text">
        <label>驾驶舱</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-2">
        <img src="../assets/images/desktop/yuanchengjiance.png" alt="" />
      </div>
      <div class="icon-text">
        <label>远程监测</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-3">
        <img src="../assets/images/desktop/zhinengkongzhi.png" alt="" />
      </div>
      <div class="icon-text">
        <label>智能控制</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-4">
        <img src="../assets/images/desktop/bianjiefuwu.png" alt="" />
      </div>
      <div class="icon-text">
        <label>便捷服务</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-5">
        <img src="../assets/images/desktop/zhinengshebei.png" alt="" />
      </div>
      <div class="icon-text">
        <label>智能设备</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-6">
        <img src="../assets/images/desktop/danganguanli.png" alt="" />
      </div>
      <div class="icon-text">
        <label>档案管理</label>
      </div>
    </div>
    <div class="box-item">
      <div class="border-light light-left"></div>
      <div class="border-light light-right"></div>
      <div class="icon-bg bg-color-7">
        <img src="../assets/images/desktop/xitongcanshu.png" alt="" />
      </div>
      <div class="icon-text">
        <label>系统参数</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style rel="stylesheet/scss" lang="scss">
.box-desktop {
  width: 100%;
  height: 850px;
  background-image: url("@/assets/images/bg.png");
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .box-item {
    width: 180px;
    height: 320px;
    background-image: radial-gradient(#0a1a3f10 60%, #1394d040 90%);
    margin: 0 15px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border: solid 2px #267de9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;

    &:hover {
      height: 420px;
      background-image: radial-gradient(#00000030 60%, #1394d040 90%);
    }

    .icon-bg {
      width: 90px;
      height: 90px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 70px;
        height: 70px;
        margin-top: 10px;
      }
    }

    .bg-color-1 {
      background-color: #9375f9;
    }
    .bg-color-2 {
      background-color: #5ca9fe;
    }
    .bg-color-3 {
      background-color: #fd6686;
    }
    .bg-color-4 {
      background-color: #fec24e;
    }
    .bg-color-5 {
      background-color: #58c185;
    }
    .bg-color-6 {
      background-color: #fd9e62;
    }
    .bg-color-7 {
      background-color: #0da6d1;
    }

    .icon-text {
      display: block;
      color: #ced5e3;
      font-weight: bold;
      margin-top: 30px;
    }

    .border-light {
      height: 50%;
      width: 2px;
      // background-color: green;
      position: absolute;
      background-image: linear-gradient(#267de9, #b0eafc, #267de9);
    }

    .light-left {
      top: 0;
      left: -2px;
    }

    .light-right {
      bottom: 0;
      right: -2px;
    }
  }
}
</style>
