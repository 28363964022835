// 封装本地存储的方式
// 整个项目使用localStorage, sessionStorage, 还是cookie
// 只需要改变这里即可
// 封装: 为了统一管理, 方便以后替换和修改
export const setStorage = (key, value) => {
  localStorage.setItem(key, stringifyItem(value))
}
export const setStorage2 = (key, value) => {
  localStorage.setItem(key, value)
}

export const getStorage = (key) => {
  return parseItem(localStorage.getItem(key))
}
export const getStorage2 = (key) => {
  return localStorage.getItem(key)
}
export const removeStorage = (key) => {
  localStorage.removeItem(key)
}
export const removeStorageAll = () => {
  localStorage.clear()
}
// 转字符串
function stringifyItem (value) {
  return JSON.stringify(value)
}
// 字符串转对象
function parseItem (value) {
  return JSON.parse(value)
}
