import request from '@/utils/Api'
/**
 * 添加采集器
 * hostName                 string            采集器名称
   address                  string            采集器地址
   collectorId              integer             集中器ID
   remark                   string              备注
 */
export const AddHost = (data) => {
  return request({
    url: '/api/archive/host/AddHost', // '/api/system/host/AddHost',
    method: 'post',
    data: data
  })
}

/**
 * 编辑采集器
 *hostName            string          采集器名称
  address             string          采集器地址
  collectorId         integer         集中器ID
  remark              string          备注
  hostId              string          采集器ID
* */
export const editHost = (data) => {
  return request({
    url: '/api/archive/host/editHost', // '/api/system/host/editHost',
    method: 'post',
    data: data
  })
}
/**
 * ********查询采集器详细********        *
 * id      query       integer     是     示例值：      1      说明：网关ID
  */
export const getHost = (id) => {
  return request({
    url: '/api/archive/host/getHost', // '/api/system/host/getHost',
    method: 'GET',
    params: {
      id
    }
  })
}
/**
 * ********删除采集器********        *
 * id      query       integer     是     示例值：      1      说明：网关ID
  */
export const deleteHosts = (ids) => {
  return request({
    url: '/api/archive/host/deleteHosts', // '/api/system/host/deleteHosts',
    method: 'GET',
    params: {
      ids
    }
  })
}
/**
 * 查询采集器列表
 *  type            integer      片区类型      1片区,2小区,3采集器
    organId         integer      类型ID
    hostName        string      采集器名称
    address         string      采集器地址
    pageSize        integer      页大小
    pageIndex       integer      页码
 */
export const getHostList = (data) => {
  return request({
    url: '/api/archive/host/getHostList', // '/api/system/host/getHostList',
    method: 'post',
    data: data
  })
}

/**
 * 导出采集器档案
 *  type            integer      片区类型      1片区,2小区,3采集器
    organId         integer      类型ID
    hostName        string      采集器名称
    address         string      采集器地址
 */
export const getHostExportData = (data) => {
  return request({
    url: '/api/archive/host/getHostExportData', // '/api/system/host/getHostExportData',
    method: 'post',
    data: data
  })
}
