import Vue from 'vue'
import {
  InputNumber,
  divider,
  Tag,
  TimePicker,
  drawer,
  DatePicker,
  card,
  upload,
  cascader,
  Main,
  Header,
  Container,
  RadioGroup,
  RadioButton,
  Radio,
  Loading,
  Switch,
  Button,
  Tree,
  table,
  TableColumn,
  pagination,
  Dialog,
  Form,
  FormItem,
  Input,
  Select,
  Message,
  Option,
  MessageBox
} from 'element-ui'
Vue.use(InputNumber)
Vue.use(divider)
Vue.use(Tag)
Vue.use(TimePicker)
Vue.use(drawer)
Vue.use(DatePicker)
Vue.use(card)
Vue.use(upload)
Vue.use(cascader)
Vue.use(Main)
Vue.use(Header)
Vue.use(Container)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Radio)
Vue.use(Switch)
Vue.use(Button)
Vue.use(Tree)
Vue.use(table)
Vue.use(TableColumn)
Vue.use(pagination)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Loading.directive)

Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$loading = Loading.service

// // 解决 弹窗重复弹出   实例存在则先通过实例方法close关掉弹窗再生成新的实例
// let messageInstace = null
// const resetMessage = (options) => {
//   if (messageInstace) {
//     messageInstace.close()
//   }
//   messageInstace = Message(options)
// };
// ['error', 'success', 'info', 'warning'].forEach(type => {
//   resetMessage[type] = options => {
//     if (typeof options === 'string') {
//       options = {
//         message: options
//       }
//     }
//     options.type = type
//     return resetMessage(options)
//   }
// })
// export const message = resetMessage
