import request from '@/utils/Api'
/**
 * ********添加集中器********
 *  collectorName             string            集中器名称
    address                   string            集中器地址
    model                     string            集中器型号
    version                   string            集中器版本
    ip                        string              IP地址
    port                      integer             端口
    apn                       string            网络接入点
    cardNo                    string              卡号
    villageId                 integer             小区ID
    remark                    string              备注
  */
export const addCollector = (data) => {
  return request({
    url: '/api/archive/collector/addCollector', // '/api/system/collector/addCollector',
    method: 'POST',
    data
  })
}

/**
 * ********编辑集中器********
*   collectorId               integer             集中器ID
 *  collectorName             string            集中器名称
    address                   string            集中器地址
    model                     string            集中器型号
    version                   string            集中器版本
    ip                        string              IP地址
    port                      integer             端口
    apn                       string            网络接入点
    cardNo                    string              卡号
    villageId                 integer             小区ID
    remark                    string              备注
  */
export const eidtCollector = (data) => {
  return request({
    url: '/api/archive/collector/eidtCollector', // '/api/system/collector/eidtCollector',
    method: 'POST',
    data
  })
}

/**
 * ********集中器详细********        * id
  */
export const getCollector = (id) => {
  return request({
    url: '/api/archive/collector/getCollector', // '/api/system/collector/getCollector',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * ********删除集中器********
 * * ids      query       integer     是     示例值：      1      说明：网关ID
  */
export const deleteCollectors = (ids) => {
  return request({
    url: '/api/archive/collector/deleteCollectors', // '/api/system/collector/deleteCollectors',
    method: 'GET',
    params: {
      ids
    }
  })
}

/**
 * ********查询集中器列表********
 *  type                    integer             片区类型             0全部 ,1片区,2小区
    organId                 integer             片区ID               当type=0，该值填0
    address                 string              网关地址
    collectorName             string              网关名称
    pageSize                integer             页大小
    pageIndex               integer             页码
*/
export const getCollectorList = (data) => {
  return request({
    // url: '/api/system/collector/getCollectorList',
    url: '/api/archive/collector/getCollectorList',
    method: 'POST',
    data
  })
}

/**
 * ********导出集中器列表********
 *  type                      integer                   片区类型                  0全部 ,1片区,2小区
    organId                   integer                   片区ID                    当type=0，该值填0
    address                   string                    网关地址
    collectorName             string                    网关名称
*/
export const getCollectorExportData = (data) => {
  return request({
    url: '/api/archive/collector/getCollectorExportData', // '/api/system/collector/getCollectorExportData',
    method: 'POST',
    data
  })
}
