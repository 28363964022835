import request from '@/utils/Api'
/**
 * ********列表********
 *  type            integer       查询类型      1.片区 2.档案
    organId         integer       片区/档案ID
    startDate       string        查询起始日期      样例 2022-03-18
    endDate         string        查询截止日期      样例 2022-03-18
    ownerCode       string        户号
    linkMan         string        联系人
    tel             string        电话
    meterNo         string        水表号
    meterType       integer       水表类型
    pageSize        integer       页大小
    pageIndex       integer       页码
  */
export const getMeterDayWaterNumList = (data) => {
  return request({
    url: '/api/record/meter/getMeterDayWaterNumList',
    method: 'POST',
    data
  })
}

/**
 * ********水表月用水量查询********
 *  type            integer       查询类型      1.片区 2.档案
    organId         integer       片区/档案ID
    startMonth       string        查询起始日期      样例 2022-03
    endMonth         string        查询截止日期      样例 2022-04
    ownerCode       string        户号
    linkMan         string        联系人
    tel             string        电话
    meterNo         string        水表号
    meterType       integer       水表类型
    pageSize        integer       页大小
    pageIndex       integer       页码
  */
export const getMeterMonthWaterNumList = (data) => {
  return request({
    url: '/api/record/meter/getMeterMonthWaterNumList',
    method: 'POST',
    data
  })
}
/**
 * ********水表年用水量查询********
 *  type            integer       查询类型      1.片区 2.档案
    organId         integer       片区/档案ID
    year            string        查询起始日期      样例 2022
    ownerCode       string        户号
    linkMan         string        联系人
    tel             string        电话
    meterNo         string        水表号
    meterType       integer       水表类型
    pageSize        integer       页大小
    pageIndex       integer       页码
  */
export const getMeterYearWaterNumList = (data) => {
  return request({
    url: '/api/record/meter/getMeterYearWaterNumList',
    method: 'POST',
    data
  })
}

/**
 * ********LORA单抄********
 *  collectorSn       string      集中器号
    meterNo           string      水表号
  */
export const singleReadMeter = (data) => {
  return request({
    url: '/api/devManager/collector/singleReadMeter',
    method: 'POST',
    data
  })
}

/**
 * ********LORA批抄********
 *  collectorSn       string      集中器号
    meterNo           string      水表号
  */
export const batchReadMeter = (data) => {
  return request({
    url: '/api/devManager/collector/batchReadMeter',
    method: 'POST',
    data
  })
}

/**
 * ********LORA集抄********
 *  villageId       integer       小区id
  */
export const assembleReadMeter = (data) => {
  return request({
    url: '/api/devManager/collector/assembleReadMeter',
    method: 'POST',
    data
  })
}

export const getLoginRightList = (data) => {
  return request({
    url: '/api/system/right/MeterReadingPermission',
    method: 'get',
    data
  })
}
