import request from '@/utils/Api'
/**
 * ********查询集中器列表********
 *  type                    integer             片区类型             0全部 ,1片区,2小区
    organId                 integer             片区ID               当type=0，该值填0
    address                 string              网关地址
    collectorName             string              网关名称
    pageSize                integer             页大小
    pageIndex               integer             页码
*/
export const getCollectorSetList = (data) => {
  return request({
    url: '/api/devManager/collector/getCollectorList',
    method: 'POST',
    data
  })
}

/**
 * ********设置集中器IP********
 *  collectorId           integer           采集器Id
    ip                    string            IP
    port                  integer           端口          整数，最大5位
  */
export const setCollectorIp = (data) => {
  return request({
    url: '/api/devManager/collector/setCollectorIp',
    method: 'POST',
    data
  })
}

/**
 * ********集中器下采集器列表********
*   collectorId               integer             集中器ID
 *  pageIndex                 integer                 页码
    pageSize                  integer                 页大小
  */
export const collectorGetHostList = (data) => {
  return request({
    url: '/api/devManager/collector/getHostList',
    method: 'POST',
    data
  })
}

/**
 * ********向采集器下载表编号-采集器列表********
 * collectorId            integer           集中器ID
   type                   integer           查询类型          1采集器名称 2采集器地址
   condition              string          查询条件
  */
export const getHostListByCondition = (data) => {
  return request({
    url: '/api/devManager/collector/getHostListByCondition',
    method: 'POST',
    data
  })
}

/**
 * ********向采集器下载表编号-表列表********
 * hostId         integer         采集器ID
   meterNo         string or null        水表号        模糊查询
  */
export const GetHostMeterList = (data) => {
  return request({
    url: '/api/devManager/collector/GetHostMeterList',
    method: 'POST',
    data
  })
}

/**
 * ********向集中器添加表档案列表********
 * collectorId          integer         集中器ID
   condition            string        条件

  */
export const getOwnerMeterList = (data) => {
  return request({
    url: '/api/devManager/collector/getOwnerMeterList',
    method: 'POST',
    data
  })
}

/**
 * ********以下为指令接口********
**/

/**
 * ********向集中器添加表档案列表********
 * collectorSn    string    集中器地址
   hostSn         string    采集器地址
  */
export const hostAutoFindMeter = (data) => {
  return request({
    url: '/api/devManager/collector/hostAutoFindMeter',
    method: 'POST',
    data
  })
}

/**
 * ********向采集器下载表编号-采集器添加表档案 ********
 * collectorSn    string    集中器地址
   hostSn         string    采集器地址
   meterSn        string  水表号
  */
export const hostAddMeter = (data) => {
  return request({
    url: '/api/devManager/collector/hostAddMeter',
    method: 'POST',
    data
  })
}

/**
 * ********向集中器添加表档案-添加表档案********  2（共4个）
 *collectorSn     string    集中器Sn
  meterSn         string    水表Sn
  */
export const collectorAddMeter = (data) => {
  return request({
    url: '/api/devManager/collector/AddMeter',
    method: 'POST',
    data
  })
}

/**
 * ********向集中器添加表档案-删除表档案********  3（共4个）
 *collectorSn     string    集中器Sn
  meterSn         string    水表Sn
  */
export const collectorDelMeter = (data) => {
  return request({
    url: '/api/devManager/collector/delMeter',
    method: 'POST',
    data
  })
}

/**
 * ********向集中器添加表档案-删除表档案********  4（共4个）
 *collectorSn     string    集中器Sn
  meterSn         string    水表Sn
  */
export const collectorAddAllMeter = (data) => {
  return request({
    url: '/api/devManager/collector/addAllMeter',
    method: 'POST',
    data
  })
}

/**
 * ********启动采集器抄表-********  4（共4个）
 *collectorSn         string        集中器地址
  hostSn              string          采集器地址
  */
export const hostReadMeter = (data) => {
  return request({
    url: '/api/devManager/collector/hostReadMeter',
    method: 'POST',
    data
  })
}

/**
 * ********集中器校时-********  4（共4个）
 *collectorSn         string        集中器地址
  setTime             string        设定时间
  isUseSystemTime     boolean       是否使用系统时间
  */
export const timing = (data) => {
  return request({
    url: '/api/devManager/collector/timing',
    method: 'POST',
    data
  })
}

/**
 * ********集中器重启-********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const reboot = (data) => {
  return request({
    url: '/api/devManager/collector/reboot',
    method: 'POST',
    data
  })
}

/**
 * ********设置集中器工作时段-********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const setWorkTime = (data) => {
  return request({
    url: '/api/devManager/collector/setWorkTime',
    method: 'POST',
    data
  })
}

/**
 * ********设置集中器网络维护时段-********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const SetNetWorkKeepTime = (data) => {
  return request({
    url: '/api/devManager/collector/SetNetWorkKeepTime',
    method: 'POST',
    data
  })
}

/**
 * ********C-2-2向集中器添加表档案-查看版本号-********
 *collectorSn         string        集中器地址
  */
export const getHostVersion = (data) => {
  return request({
    url: '/api/devManager/collector/getHostVersion',
    method: 'POST',
    data
  })
}

/**
 * ********C-2-3向集中器添加表档案-添加采集器档案-********
 *collectorSn         string        集中器地址
  */
export const devAddHost = (data) => {
  return request({
    url: '/api/devManager/collector/AddHost',
    method: 'POST',
    data
  })
}

/**
 * ********C-2-4向集中器添加表档案-删除全部采集器档案********
 *collectorSn         string        集中器地址
  */
export const devDelAllHost = (data) => {
  return request({
    url: '/api/devManager/collector/delAllHost',
    method: 'POST',
    data
  })
}

/**
 * ********C-3-1读取集中器工作时段********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const getWorkTime = (data) => {
  return request({
    url: '/api/devManager/collector/getWorkTime',
    method: 'POST',
    data
  })
}

/**
 * ********C-4-1读取集中器网络维护时段********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const getNetWorkKeepTime = (data) => {
  return request({
    url: '/api/devManager/collector/getNetWorkKeepTime',
    method: 'POST',
    data
  })
}

/**
 * ********C-5-1读取采集器中继列表********  4（共4个）
 *collectorSn         string        集中器地址
  */
export const getHostRouteList = (data) => {
  return request({
    url: '/api/devManager/collector/getHostRouteList',
    method: 'POST',
    data
  })
}
