import request from '@/utils/Api'
export * from './setting/user.js'
export * from './setting/right.js'
export * from './setting/role.js'
export * from './setting/organ.js'
export * from './setting/waterPrice'
export * from './setting/sysParamsSet'
export * from './archives/gateway'
export * from './archives/owner'
export * from './archives/ownerInfo'
export * from './archives/village'
export * from './archives/Collector'
export * from './archives/host'
export * from './archives/meter'
export * from './archives/OpenMeter'
export * from './DevControl/Collector'
export * from './DevControl/ScheduleSet'
export * from './DevControl/Meter'
export * from './DevControl/GatewaySet'
export * from './DevControl/electronRemoteTransmission'
export * from './Statistic/Statistic'
export * from './Analysis/DayRecord'
export * from './Analysis/RealData'
export * from './setting/log'

// const req = require.context('./archives', false, /\.js$/)

// const requireAll = requireContext => {
//   requireContext.keys().map(requireContext)
// }
// requireAll(req)

// 登录
export const login = (data) => request({
  url: '/api/system/login/login',
  method: 'POST',
  data: data
})

// 修改登录密码
// userName              string        用户名
// oldPassword           string        旧密码
// password              string        新密码
// repeatPassword        string        确认新密码
export const changePassword = (data) => request({
  url: '/api/system/login/changePassword',
  method: 'POST',
  data: data
})

// 查询异常报警列表
//  ownerCode       string        户号
//  linkMan         string        联系人
//  tel             string        电话
//  meterNo         string        水表号
//  meterType       integer       水表类型      1=LORA,2=NB,3=NB分体 0全部
//  type            integer       片区类型
//  organId         integer       片区ID/档案ID
//  pageIndex       integer       页码
//  pageSize        integer       页大小
//  alarmType       integer       报警类型      -1=全部 1=低电压 2=通讯故障 3=用水异常 4=阀门异常
export const getAlarmList = (data) => request({
  url: '/api/alarm/Alarm/GetAlarmWorkList',
  method: 'POST',
  data: data
})

export const GetAlarmWorkForCockpitList = (para) => request({
  url: '/api/alarm/Alarm/GetAlarmWorkForCockpitList',
  method: 'get',
  para
})

export const GetOwnerRemoteMeterList = (data) => request({
  url: '/api/devManager/collector/GetOwnerRemoteMeterList',
  method: 'POST',
  data: data
})

export const GetAlertType = (params) => request({
  url: '/api/Dict/DictItemManagement/GetAlertType',
  method: 'get',
  params,
})

export const GetWaterMeterType = (params) => request({
  url: '/api/Dict/DictItemManagement/GetWaterMeterType',
  method: 'get',
  params,
})
