import request from '@/utils/Api'
/**
 * 添加水表
 * villageId                 integer               小区ID
   meterType                 integer               水表类型              1=LORA,2=NB,3=NB分体              meterVender
   string                    运营商                 电信,联通,移动
   isValve                   integer               是否阀控              0是 1否
   installDate               string                安装日期
   meterNo                   string                表号
   collectorId               integer               集中器ID              没有值补0
   hostId                    integer               采集器ID              没有值补0
   meterBaseNum              number                表底数
   useWaterType              integer               用水类型
   deviceId                  string                设备ID
   runCode                   string                运营编码
 */
export const addMeter = (data) => {
  return request({
    url: '/api/archive/meter/addMeter', // '/api/system/meter/addMeter',
    method: 'post',
    data: data
  })
}

/**
 * 编辑水表
 * meter_id                  integer               水表ID
* villageId                  integer               小区ID
   meterType                 integer               水表类型              1=LORA,2=NB,3=NB分体              meterVender
   string                    运营商                 电信,联通,移动
   isValve                   integer               是否阀控              0是 1否
   installDate               string                安装日期
   meterNo                   string                表号
   collectorId               integer               集中器ID              没有值补0
   hostId                    integer               采集器ID              没有值补0
   meterBaseNum              number                表底数
   useWaterType              integer               用水类型
   deviceId                  string                设备ID
   runCode                   string                运营编码
 */
export const editMeter = (data) => {
  return request({
    url: '/api/archive/meter/editMeter', // '/api/system/meter/editMeter',
    method: 'post',
    data: data
  })
}

// 获取水表信息
export const getMeter = (meterId) => {
  return request({
    url: '/api/archive/meter/getMeter', // '/api/system/meter/getMeter',
    method: 'get',
    params: {
      meterId
    }
  })
}

// 删除水表信息
export const deleteMeter = (meterId) => {
  return request({
    url: '/api/archive/meter/deleteMeter', // '/api/system/meter/deleteMeter',
    method: 'get',
    params: {
      meterId
    }
  })
}

/**
 * 查询水表信息
 * meterNo                    string                  水表                  模糊查询
   meterType                  integer                 水表类型                1=LORA,2=NB,3=NB分体,0=全部
   type                       integer                 片区类型                1.片区 2.档案
   organId                    integer                 片区/档案ID
   bindStatus                 integer                 绑定业主状态                0未绑定 1已绑定 -1=全部
   pageSize                   integer                 页大小
   pageIndex                  integer                 页码
 */
export const getMeterList = (data) => {
  return request({
    url: '/api/archive/meter/getMeterList', // '/api/system/meter/getMeterList',
    method: 'post',
    data: data
  })
}

/**
 * 未绑定业主信息
 * villageId   小区ID
 */
export const getNoBindOwnerList = (data) => {
  return request({
    url: '/api/archive/meter/getNoBindOwnerList', // '/api/system/meter/getNoBindOwnerList',
    method: 'post',
    data: data
  })
}

/**
 * 绑定 和 解绑 业主
 * type        integer        绑定类型        1绑定 2解绑
meterId        integer        水表ID
ownerId        string        业主ID
 */
export const bindMeterOwner = (data) => {
  return request({
    url: '/api/archive/meter/bindMeterOwner', // '/api/system/meter/bindMeterOwner',
    method: 'post',
    data: data
  })
}

/**
 * 更换水表
 * meterId                   integer             旧水表Id
   meterNo                   string              新水表号
   installDate               string              安装时间
   isValve                   integer             是否阀控
   meterVender               string              运营商            电信,联通,移动
   meterType                 integer             水表类型            1=LORA,2=NB,3=NB分体
   meterBaseNum              number              表底数
   hostId                    integer             集中器ID
   collectorId               integer             采集器ID
   useWaterType              integer             用水类型
   runCode                   string              运营编码
   deviceId                  string              设备ID
   payMode                   integer             缴费方式            1.预付费 2.后付费
   leftWater                 number              剩余水量
 */
export const changeMeter = (data) => {
  return request({
    url: '/api/archive/meter/changeMeter',
    method: 'post',
    data: data
  })
}

/**
 * 导出水表列表
 * 请求参数与"查询水表列表"保持一致
 */
export const getMeterListExportData = (data) => {
  return request({
    url: '/api/archive/meter/getMeterListExportData',
    method: 'post',
    data: data
  })
}


//AEP产品下拉框接口
export const getAepProductList = (data) => {
  return request({
    url: '/api/archive/Village/GetAepProductList',
    method: 'GET'
  })
}

//水表配置AEP产品-批量配置 (Auth)
export const MeterRelAepProduct = (data) => {
  return request({
    url: '/api/archive/Village/MeterRelAepProduct',
    method: 'post',
    data: data
  })
}

export const GetProjectsList = (data) => {
  return request({
    url: '/api/archive/Meter/GetProjectsList',
    method: 'post',
    data: data
  })
}

export const GetAllMeterList = (data) => {
  return request({
    url: '/api/archive/Meter/GetAllMeterList',
    method: 'post',
    data: data
  })
}
