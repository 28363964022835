<template>
  <span class="menu-item" :class="menuItemActive ? 'menuItemActive':''">

      <span class="text">
        <i v-if="icon" :class="['iconfont', icon]" style="margin-right: 10px;"></i>
        <span><slot></slot></span>
      </span>


  </span>
</template>

<script>
export default {
  props: {
    icon: String,
    href: String,
    menuItemActive: Boolean
  },
  data () {
    return {
    }
  },
  mounted() {

  },
  watch: {
    menuItemActive: {
      handler (newV, oldV) {
      }
    }
  }

}
</script>

<style  lang="scss" scoped>
.menu-item {
  width: 140px;
  line-height: 35px;
  font-weight: 400;
  // font-style: italic;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  margin:0 10px;
  cursor: pointer;
 background-color: #2c58a3;
  border-radius: 10px;
  text-align: center;


  .text {
    i {
      font-size: 17px;
     // margin-right: 8px;
    }
    i,
    span {
      vertical-align: middle;
    }
    //padding: 0 60px;
    text-align: center;
    display: inline-block;
  }

  .container {
    display: inline-block;
    height: 100%;
  }


}

.menuItemActive,.menu-item:hover{
  background-color: #1963e7;
}
</style>
