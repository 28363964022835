<template>
  <div class="thePage">
    <el-pagination popper-class="popperDrowDown" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="thePage.pageIndex" :page-size="thePage.pageSize" layout="total, sizes, pager, next, jumper" :total="thePage.dateTotal"
      :page-sizes="[10,30,50,100]">

    </el-pagination>
  </div>
</template>
<script>
  export default {
    props: ['thePage'],
    data() {
      return {

      }
    },

    methods: {
      handleSizeChange(val) {
        this.$parent.page.pageSize = val
        this.$parent.page.pageIndex = 1
        this.$parent.getList() //这样写的缺点是父组件中表格的方法必须叫getList，优点是在父组件中子组件的使用处不用自定义事件以及所对应的方法少了好几行代码
      },
      handleCurrentChange(val) {
        this.$parent.page.pageIndex = val
        this.$parent.getList()
      }
    }
  }
</script>
<style lang="scss" scoped>
  .thePage {
    width: 100%;
    text-align: center;

    ::v-deep .el-pagination {
      padding: 15px 8px !important;

      .el-pagination__total,
      .el-pagination__jump,
      input {
        color: #fff;
        background: none;
      }

      .btn-prev,
      .btn-next {
        background: none;
      }

      .el-pager {
        .number {
          background: none;
        }
      }
    }
  }

  ::v-deep .el-popup-parent--hidden {
    .el-tree {
      background: none;

      .el-tree-node {
        &:hover {
          background: rgba(75, 10, 255, 0.4);

          .el-tree-node__content:hover {
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }

      .el-tree-node.is-current>.el-tree-node__content {
        background: rgba(0, 0, 0, 0.6);
      }

    }
  }

  ::v-deep .el-select-dropdown {
    background: none;
  }
</style>
