<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  // background: #142b85;
  background: url("@/assets/images/bg.png") no-repeat;
  background-size: 100% 100%;
  .popperclassForMaindatepageLinContent {
    .el-date-range-picker__header {
      div {
        color: #363636;
      }
    }
  }
}
#app {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //background: url("./assets/images/bg.png") no-repeat;
  background-size: 100%;

}

.login_image .el-input__inner {
  border: none !important;
  background: transparent;
}
.login_phone .el-input__inner {
  border: none !important;
  background: transparent;
}
.login_captcha .el-input__inner {
  border: none !important;
  background: transparent;
}
// 修改全局滚动条样式
::-webkit-scrollbar-track {
  background-color: #c8cccf;
   border-radius: 0px;
   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #4989b5;
   -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

</style>
