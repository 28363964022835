import request from '@/utils/Api.js'
/*
  ***添加片区***................................................
type                  integer               添加片区类型              1顶级 2平级 3下级
organId               integer               片区ID                   类型为顶级时该只为0
organName             string                片区名称
linkMan               string                联系人
tel                   string                电话
remark                string                备注
provinceId            string                行政省ID
cityId                string                行政市ID
countyId              string                行政区/县ID
WeChatMerchantId            string                微信商户号            直接连为自己商户号，平台设置为子商户号
wechatappid                 string                微信APPID           直接连为自己APPID，平台设置为子商户微信APPID
*/
export const addOrgan = (data) => {
  const obj = data
  return request({
    url: '/api/system/organ/addOrgan',
    method: 'POST',
    data: obj
  })
}

/*
  ***编辑片区***
organId                     integer               片区ID                   类型为顶级时该只为0
organName                   string                片区名称
linkMan                     string                联系人
tel                         string                电话
remark                      string                备注
provinceId                  string                行政省ID
cityId                      string                行政市ID
countyId                    string                行政区/县ID
WeChatMerchantId            string                微信商户号            直接连为自己商户号，平台设置为子商户号
wechatappid                 string                微信APPID           直接连为自己APPID，平台设置为子商户微信APPID
IsModifySubOrgPaymentInfo   string                integer             是否修改下属机构的商户信息
*/
export const editOrgan = (data) => {
  const obj = data
  return request({
    url: '/api/system/organ/editOrgan',
    method: 'POST',
    data: obj
  })
}

/* ***删除片区*** */
export const deleteOrgans = (organIds) => {
  return request({
    url: '/api/system/organ/deleteOrgans',
    method: 'GET',
    params: {
      organIds
    }
  })
}

/*
  ***获取片区树***
  pageSize          integer             条数
  pageIndex         integer             当前页
*/
export const getOrganTree = () => {
  return request({
    url: '/api/system/organ/getNavigateTree',
    method: 'GET'
  })
}
/*
  ***查询片区详情***
  pageSize          integer             条数
  pageIndex         integer             当前页
*/
export const getOrgan = (organId) => {
  return request({
    url: '/api/system/organ/getOrgan',
    method: 'GET',
    params: {
      organId
    }
  })
}

/*
  ***片区下拉列表联动***
  parentId           query        string        否        说明：联动ID,最顶级填写0
*/
export const getOrganSelectListItemList = (parentId) => {
  return request({
    url: '/api/system/organ/getOrganSelectListItemList',
    method: 'get',
    params: {
      parentId
    }
  })
}

/*
  ***查询某个片区以及直接子级片区***
  organId                          说明：片区ID
  pageSize          integer             条数
  pageIndex         integer             当前页
*/
export const getOrganList = (data) => {
  return request({
    url: '/api/system/organ/getOrganList',
    method: 'POST',
    data
  })
}

/*
  ***行政区域联动***
  parentId                          父节点ID 默认为1
*/
export const getAreaSelectListItemList = (parentId) => {
  return request({
    url: '/api/system/organ/getAreaSelectListItemList',
    method: 'GET',
    params: {
      parentId
    }
  })
}
/*
  ***根据条件查询数据导航树2***
navigateLevel   query   integer  是   说明： 导航类型[ 省 = 1, 供水公司 = 2, 运营处 = 3, 小区 = 11, 楼栋 = 12, 单元 = 13, 门牌号 = 14, 集中器 = 21 ]
*/
export const getNavigateTree2 = (navigateLevel) => {
  return request({
    url: '/api/system/organ/getNavigateTree2',
    method: 'GET',
    params: {
      navigateLevel
    }
  })
}

/*
***右侧村级(小区级)区域联动下拉列表***
type              integer           查询类型          1片区 2小区
organId           integer           类型关联Id
*/
// export const getVillageSelectListItemList = (data) => request({
//   url: '/api/system/village/getVillageSelectListItemList',
//   method: 'POST',
//   data
// })
