<template>
  <div class="roleLeft" :style="{'width':!isCollapse?'200px':'64px'}">
    <div class="title-side">
        <span v-show="!isCollapse">水在线智控平台</span>
        <i class="collapse-icon" :class="isCollapse?'el-icon-s-unfold':'el-icon-s-fold'" @click="changeCollapse"></i>
    </div>
    <div v-for="(item) in leftMenu" :key="item.rightId" @click.capture="showItemChildren(item)">
   <!-- 菜单权限里没有加是否隐藏的字段，所以前端自己用菜单名子是否包含0来判断，如果想要这个菜单隐藏，就给这个菜单的名字加个0 -->
      <div v-if="item.rightName.indexOf('0') == -1">
        <el-menu  class="el-menu-vertical-demo" background-color="transparent" text-color="#fff" :collapse="isCollapse">
          <el-submenu v-if="item.childrenM" :class="item.rightId==correntItem ?'isActive2':''" >
            <template slot="title">
             <i :class="item.icon" class="iconfont"></i>             
              <span>{{ item.rightName }}</span>
            </template>

            <el-menu-item v-for="(item2, index2) in item.childrenM" :key="index2" :class="activePath == item2.path ?'isActive':''" v-if="item2.rightName.indexOf('0') == -1">
              <router-link v-if="item2.rightName.indexOf('0') == -1"  :to="item2.path" >{{ item2.rightName}}</router-link>
            </el-menu-item>
          </el-submenu>
          <el-menu-item v-else  :class="activePath == item.path ?'isActive':''">
           <i :class="item.icon" class="iconfont"></i>
            <span slot="title">{{ item.rightName }}</span>
          </el-menu-item>

        </el-menu>

      </div>
    </div>
  </div>
</template>

<script>
  export default {

    props: ['leftMenuData', ],
    // props: {
    //   leftMenuData:{
    //     type:Object,
    //   },
    //   childrenm:{
    //     type:Array
    //   }
    // },
    data() {
      return {
        isCollapse:false,
        leftMenu: [],
        correntItem: 0,
        isShow: false,
        activePath:this.$route.path, //当前路由
      }
    },
    watch: {
      // 侦听第几个一级目录
      leftMenuData: {
        deep: true,
        handler(newV, oldV) {
          this.leftMenu = newV
          this.correntItem = this.leftMenu[0].rightId
        }
      },
      //监听路由的变化
       $route(to,from){
          this.activePath=to.path
       }


    },
    mounted() {


      this.$bus.$on('menuId', res => { // 点击一级菜单进入、设置默认的二级菜单高亮
        if (!this.correntItem) {
          this.correntItem = res
        }
      })
      this.correntItem = this.leftMenuData.rightId
      this.leftMenuData.forEach((item, index) => {
        const newArr = []
        this.leftMenu[index] = item
        this.checkedList(item, newArr)
        this.leftMenu[index].path = newArr[0]
      })
    },
    methods: {
      changeCollapse(){
         this.isCollapse=!this.isCollapse;
         this.$parent.isCollapse=this.isCollapse;//直接修改父组件数据的值,

      },
      showItemChildren(item) {
        if (item.rightId) {
          this.correntItem = item.rightId
          if (item.childrenM) {
            this.isShow = true
          } else {
            this.isShow = false
          }
          // this.$router.push(path)
          const path = item.path
          this.$router.push({
            path
          })
        }
      },
      hiddenItemChildren() {
        setTimeout(() => {
          this.isShow = false
        }, 1000)
      },
      isChildren(data) {
        let children = 1
        children = []
        data.forEach(item => {
          if (item.rightType !== 3) {
            children.push(item)
          }
        })
        return children
      },
      // 查出目录url
      checkedList(data, newArr) {
        data.forEach(item => {
          if (item.rightType === 2) {
            newArr.push(item.path)
          } else {
            if (item.children !== null && item.children.length !== 0 && item.rightType === 1) {
              this.checkedList(item.children, newArr)
            }
          }
        })
      }
    }

  }
</script>

<style lang="scss" scoped>
  .el-menu {
    border-right: 0 !important;
    .el-menu-item{
      text-align: left;
    }
    ::v-deep .el-submenu__title{
       text-align: left !important;
    }
    .isActive{
          background-color: #1d43b5!important;
          border-left: 4px solid #5a7bdc !important;
    }
    ::v-deep .isActive2 .el-submenu__title{
      background-color: #1d43b5!important;
      border-left: 4px solid #5a7bdc !important;
    }
    a{
      color:#fff;
      display: block;
      width: 100%;
      height: 100%;
    }
  }


  .roleLeft {
    font-size: 12px;
    width: 200px;
    //margin: .2083rem  0.0417rem 0 0.0417rem;
    // margin-top:0.2083rem;
    height: calc(100vh - 140px);
    // background-color: #0d2275;
    border-right: 1px solid #fff;
    overflow: auto;
    flex-shrink: 0;
    ::v-deep .el-menu-item:hover, ::v-deep .el-submenu__title:hover{
      background-color: #1d43b5 !important;
    }

    .liftItem {
      width: 40px;
      height: 124px;
      background: url("@/assets/images/public/left_nav.png") no-repeat;
      background-size: 100%;
      display: flex;
      position: relative;
      cursor: pointer;

      span {
        width: 40px;
        height: 124px;
        word-wrap: break-word;
        writing-mode: tb-rl;
        letter-spacing: 8px;
        text-align: center;
        margin-left: -14px;
        text-shadow: 4px 4px 4px #000000;

        a {
          color: #fff;
        }
      }

      .liftItemChildren {
        width: 128px;
        height: 240px;
        background: url("@/assets/images/public/bg_left_nav_menu.png") no-repeat;
        background-size: 92% 93%;
        position: absolute;
        left: 36px;
        z-index: 20;
        padding-top: 10px;
        display: none;

        .childrenList {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 220px;
          cursor: default;
          padding: 8px 10px 10px 8px;

          div:hover {
            text-shadow: 1px 1px 1px #000000;
          }

          div {
            padding-left: 4px;
            flex: 1;
            height: 36px;
          }

          a {
            color: #fff;
          }
        }
      }

      .show {
        display: block;
      }

      .hidden {
        display: none;
      }
    }

    .isHavueChildren {
      font-weight: 900;
      font-size: 13px;
    }

    .liftItemActive {
      background: none;
      background: url("@/assets/images/public/left_active_nav.png") no-repeat;
      background-size: 100%;
    }
  }
  .title-side{
        background: #061552;
        color: rgb(255, 255, 255);
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        position: relative;
        i{
          position: absolute;
          right: 10px;
          font-size: 20px;
          top:15px
        }

  }
  .el-menu-vertical-demo {
    .iconfont{
      margin-right: 10px;
      color:#fff
    }
  }
</style>
