import request from '@/utils/Api.js'
/*
  ***日用水量统计***................................................
type                  integer               添加片区类型              1顶级 2平级 3下级
organId               integer               片区ID                   类型为顶级时该只为0
meterType             integer               水表类型
date                  string                请求日期
*/
export const dayStatList = (data) => {
  const obj = data
  return request({
    url: '/api/stat/stat/dayStatList',
    method: 'POST',
    data: obj
  })
}

/*
  ***月用水量统计***................................................
type                  integer               添加片区类型              1顶级 2平级 3下级
organId               integer               片区ID                   类型为顶级时该只为0
meterType             integer               水表类型
month                  string                请求日期
*/
export const monthStatList = (data) => {
  const obj = data
  return request({
    url: '/api/stat/stat/monthStatList',
    method: 'POST',
    data: obj
  })
}

/*
  ***年用水量统计***................................................
type                  integer               添加片区类型              1顶级 2平级 3下级
organId               integer               片区ID                   类型为顶级时该只为0
meterType             integer               水表类型
year                  string                请求日期
*/
export const yearStatList = (data) => {
  const obj = data
  return request({
    url: '/api/stat/stat/yearStatList',
    method: 'POST',
    data: obj
  })
}
export const DayMonthYearStatistics = (data) => {
  const obj = data
  return request({
    url: '/api/stat/Stat/NewDayMonthYearStatistics',
    method: 'POST',
   data,
  })
}

export const GetAlarmStat = (data) => { 
  return request({
    url: '/api/alarm/Alarm/GetAlarmStat',
    method: 'POST',
   data,
  })
}
