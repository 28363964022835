<!-- 不用的 -->
<template>
  <div class="menu">
    <!-- {{menu}} -->
    <div class="top-menu">
      <div style="border:1px solid #0c2452;background:#0f3371;padding:.03rem;font-size:.06rem;color:#fff;position: absolute;left:0px;top:70px;z-index:1000000;">
        <router-link :to="{name: 'cloudPlatform'}" href="javascript:" style="color:#fff;">云平台导航</router-link>
      </div>
      <div class="left">
        <router-link :to="{path:'/waterCloudPlatform'}" href="javascript:" style="color:#fff;font-size:20px;">
          <img src="./../../assets/images/logo.png" style="margin-right:15px;width:90px;" alt="">
          <span>智慧供水运营信息云平台</span>
        </router-link>
      </div>
      <div class="middle" >
                  <top-menu-item v-for="(item, index) in menu" :key="item.rightId" :href="item.path" :icon="icon[index]" :menuItemActive="index === activationIndex ? true: false" @click.native="leftMenuId(index, item.path)">
          {{item.rightName}}
          </top-menu-item >
        <!-- <router-link to="/setting/">
          <top-menu-item icon="icon-shuye" :menuItemActive="path==='Setting' ? true: false">系统参数</top-menu-item>
        </router-link> -->
      </div>
      <div class="right">
        <span class="hello"><img src="@/assets/images/avatar.svg" /> 您好！</span>
          <span class="name" @click="changePasswordToogle()">{{userName}}</span>
        <a class="logout" href="#" @click="logout()"> <i class="iconfont icon-tuichu"></i>退出</a>
      </div>
    </div>
    <el-dialog title="修改密码" :visible.sync="updatePassDialogFormVisible" width="20%">
      <el-form :model="passForm"  :rules="rules" ref="ruleForm">
        <el-form-item label="用户名" label-width="80px">
          <el-input v-model="passForm.userName" size="mini"  :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="旧 密 码" label-width="80px" prop="oldPassword">
          <el-input type="password" v-model="passForm.oldPassword" autocomplete="new-password"  size="mini"></el-input>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="新 密 码" label-width="80px" prop="password">
          <el-input  type="password" v-model="passForm.password"  autocomplete="new-password" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="确定密码" label-width="80px" prop="repeatPassword">
          <el-input type="password"  v-model="passForm.repeatPassword" autocomplete="new-password"  size="mini"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="updatePassDialogFormVisible = false"  size="mini">取 消</el-button>
        <el-button type="primary" @click="changePassword()"  size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TopMenuItem from './TopMenuItem.vue'
import '@/utils/global'
import { getStorage, setStorage } from '@/utils/storage'
import { changePassword } from '@/ApI/index'
export default {
  components: { TopMenuItem },
  data () {
    let path = this.$route.path.split('/')
    path = path[1]
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      path,
      menu: [],
      activePath:'',
      icon: ['icon-jiankongjiashicang', 'icon-guifandunpai', 'icon-case-file-full', 'icon-shebei', 'icon-tedianshandianfahuo', 'icon-shuye', 'el-icon-s-tools'],
      activationIndex: '',
      userName: '用户',
      updatePassDialogFormVisible: false,
      passForm: {
        userName: '用户',
        oldPassword: '',
        password: '',
        repeatPassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        repeatPassword: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      UserMenusListToButton: '' // 全部权限内容，目的是获取按钮
    }
  },
  watch: {
    activationIndex: {
      deep: true,
      handler (newV, oldV) {
        this.$parent.leftMenuId = this.activationIndex
        if (!newV && newV !== 0) { // 如果丢失从记录里查找
          this.activationIndex = getStorage(this.$storageKey.leftMenuId)
          this.$parent.leftMenuId = this.activationIndex
        }
      }
    },
  },
  mounted () {   
    if (!this.activationIndex) {
      this.activationIndex = getStorage(this.$storageKey.leftMenuId) // 一级菜单状态丢失后从记录中获取
    }
    // 获取导航
    let menuList = getStorage(this.$storageKey.UserMenusList)
    if(!menuList){
      menuList = []
    }
    if(menuList.length>0){
      menuList = menuList.filter((obj)=>{
        return (obj.platformType != null &&  obj.platformType != 1);
      })
    }
    this.UserMenusListToButton = JSON.stringify(getStorage(this.$storageKey.UserMenusList))
    setStorage(this.$storageKey.allButton, this.UserMenusListToButton)
    // 导航排序
    // menuList.sort((a, b) => { return a.order - b.order })  后端已经排序了
    // 生成目录的url
    menuList.forEach((item, index) => {
      let newArr = [] // 选中的
      newArr = []
      // this.checkedList(item.path, menuList, newArr)
      // if (newArr[0]) {
      //   menuList[index].path = newArr[0]
      // } else {
      //   menuList[index].path = newArr[1]
      // }
      // menuList[index].path4 =
      // menuList[index].path =
      this.checkedList(item.children, newArr)
      menuList[index].path = newArr[0]
    })
    // 生成一级导航数据
    let menuListIndex = -1
    menuList.forEach((item, index) => {
      if (item.pid === 0 && item.rightType === 1) {
        menuListIndex++
        this.menu.push(item)
        if (item.path && item.path.includes(this.path)) {
          this.$parent.leftMenuId = menuListIndex
          // 第几个一级目录（用来确定二级目录）
          setStorage(this.$storageKey.leftMenuId, menuListIndex)
        }
      }
    })

    console.log(this.menu,1111)
    this.$parent.topMenu = this.menu
    // 获取用户名
    this.userName = getStorage(this.$storageKey.UserInfo)
    this.passForm.userName = this.userName.trim()
    // 驾驶舱清空一级菜单高亮
    if (this.$route.fullPath === '/Cockpit/Index') {
      this.activationIndex = ''
    }
  },
  methods: {
    changePasswordToogle(){
      this.updatePassDialogFormVisible = true
      this.$set(this.passForm, "oldPassword", "");
      this.$set(this.passForm, "password", "");
      this.$set(this.passForm, "repeatPassword", "");
    },
    // 一级菜单点击事件
    leftMenuId (index, path) {
      this.$parent.leftMenuId = index // 第几个一级菜单下的二级菜单
      setStorage(this.$storageKey.leftMenuId, index)
      this.activationIndex = index // 一级菜单高亮
      this.$router.push(path)
    },
    logout () {
      localStorage.clear()
      this.$message.success('您已退出登录')
      this.$router.push('/login')
    },
    async changePassword () {
      const { data: res } = await changePassword(this.passForm)
      if (res.success) {
        this.$message.success(res.message)
        this.updatePassDialogFormVisible = false
        this.$set(this.passForm, "oldPassword", "");
        this.$set(this.passForm, "password", "");
        this.$set(this.passForm, "repeatPassword", "");
        setTimeout(()=>{
          this.logout();
          },1000)

      } else {
        this.$message.error(res.message)
      }
    },
    // 查出目录url  目前已经不用这个了
    checkedList0 (path, data, newArr) {
      data.forEach(item => {
        if (item.rightType === 2) {
          newArr[1] = item.path
        }
        if (item.path === path && item.rightType === 2) {
          // if (item.children.length === 0) { // item.children  &&
          // newArr.push(item.path)
          // }
          newArr[0] = path
        } else {
          if (item.children !== null && item.children.length !== 0 && item.rightType === 1) {
            this.checkedList(path, item.children, newArr)
          }
        }
      })
    },
    // 查出目录url
    checkedList (data, newArr) {
      data.forEach(item => {
        if (item.rightType === 2) {
          newArr.push(item.path)
        } else {
          if (item.children !== null && item.children.length !== 0 && item.rightType === 1) {
            this.checkedList(item.children, newArr)
          }
        }
      })
    }
  }
}
</script>

<style  lang="scss" scoped>
.menu {
  max-width: 1920px;
  .top-menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    height: .3802rem;
    min-width: 1366px;
    min-height: 60px;
    background: url("~@/assets/top-bg.png");
    background-size: 100% 100%;
    // padding-left: .1927rem;
    // position: relative;
    box-sizing: border-box;

    .left {
      padding-left: 20px;
      width: 380px;
      font-size: 24px;
      font-weight: 400;
      // font-style: italic;
      color: #fefeff;
      line-height: 63px;
      height: 100%;
      display: inline-block;
      letter-spacing:3px;
      img{
        padding-right: 5px;
        width: 32px;
        height: 32px;
        margin-top: -3px;
      }
    }

    .sub {
      width: 87px;
      height: 16px;
      font-size: 15px;
      font-weight: 400;
      color: #8fbbdd;
      line-height: 44px;
    }

    .middle {
      flex: 1;
      width: 6.7rem; // 5.7292rem;
      font-size: 13px;
      min-width: 840px;
      display: flex;
      justify-content: center;
      .menu-item{
        width: 100%;
        max-height: .276rem;
        min-height: 0.1rem;
        flex: 1;
        overflow: hidden;
       ::v-deep .text {
            padding: 0 20px;
           ::v-deep i {
                font-size: .0885rem;
                margin-right: 0px !important;
            }
        }
      }
        // .containerActive{
        //   .containerActive{
        //     color: #fff;
        //     background: radial-gradient(#1d57bf 40%, #1394d0 94%) !important;
        //   }
        // }
    }

    .right {
      font-size: 13px;
      font-weight: 400;
      color: #10e7e6;
      line-height: 56px;
      min-width: 258px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      .logout {
        color: inherit;
        i {
          margin-right: 4px;
        }
      }
      .hello {
        font-weight: 400;
        color: #10e7e6;
        margin: 0;
        img {
          width: 28px;
          height: 28px;
          vertical-align: middle;
          margin-right: 2px;
        }
      }
      .name {
          color: #ffffff;
          cursor:pointer;
        }
    }
  }
}
</style>
