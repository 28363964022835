import request from '@/utils/Api'
/**
 * ********添加网关********
 *  gatewayName                         string                        网关名称
    address                             string                        网关地址
    ip                                  string                        IP地址
    port                                integer                       端口
    apn                                 string                        网络接入点
    cardNo                              string                        卡号
    villageId                           integer                       小区ID
    remark                              string                        备注
  */
export const addGateway = (data) => {
  return request({
    url: '/api/archive/gateway/addGateway', // '/api/system/gateway/addGateway',
    method: 'POST',
    data
  })
}

/**
 * ********编辑网关********
 * gatewayId                            integer                       网关ID
 * gatewayName                          string                        网 关名称
    address                             string                        网关地址
    ip                                  string                        IP地址
    port                                integer                       端口
    apn                                 string                        网络接入点
    cardNo                              string                        卡号
    villageId                           integer                       小区ID
    remark                              string                        备注
  */
export const editGateway = (data) => {
  return request({
    url: '/api/archive/gateway/editGateway', // '/api/system/gateway/editGateway',
    method: 'POST',
    data
  })
}

/**
 * ********查询网关详细********        * gatewayId      query       integer     是     示例值：      1      说明：网关ID
  */
export const getGateway = (id) => {
  return request({
    url: '/api/archive/gateway/getGateway', // '/api/system/gateway/getGateway',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * ********删除网关********
 * * ids      query       integer     是     示例值：      1      说明：网关ID
  */
export const deleteGateways = (ids) => {
  return request({
    url: '/api/archive/gateway/deleteGateways', // '/api/system/gateway/deleteGateways',
    method: 'GET',
    params: {
      ids
    }
  })
}

/**
 * ********查询网关列表********
 *  type                    integer             片区类型             0全部 ,1片区,2小区
    organId                 integer             片区ID               当type=0，该值填0
    address                 string              网关地址
    gatewayName             string              网关名称
    pageSize                integer             页大小
    pageIndex               integer             页码
*/
export const getGatewayList = (data) => {
  return request({
    url: '/api/archive/gateway/getGatewayList', // '/api/system/gateway/getGatewayList',
    method: 'POST',
    data
  })
}

/**
 * ********导出网关列表********
 *  type                      integer                   片区类型                  0全部 ,1片区,2小区
    organId                   integer                   片区ID                    当type=0，该值填0
    address                   string                    网关地址
    collectorName             string                    网关名称
*/
export const GetGatewayExportData = (data) => {
  return request({
    url: '/api/archive/gateway/GetGatewayExportData', // '/api/system/gateway/GetGatewayExportData',
    method: 'POST',
    data
  })
}
/**
 * ********导入网关列表********
 * file      file      否      说明：上传的文件
*/
export const importGatewayData = (data) => {
  return request({
    url: '/api/archive/gateway/importGatewayData', // '/api/system/gateway/importGatewayData',
    method: 'POST',
    data
  })
}


export const GatewayRelAepProduct = (data) => {
  return request({
    url: '/api/archive/Village/GatewayRelAepProduct',
    method: 'POST',
    data
  })
}

