<template>
  <div class="OrgTree">
          <h4>行政划分</h4>
          <el-tree ref="organTree" :data="AreaTree" :props="defaultPropsTree" node-key="organId"
            :highlight-current = "true"
            :default-checked-keys="defaultOrganId"
            :default-expanded-keys="defaultOrganId"
            accordion
            @node-click="handleNodeClick">
            <!-- default-checked-keys  ===  默认勾选的节点的 key 的数组
                 default-expanded-keys ===  默认展开的节点的 key 的数组
            -->
            </el-tree>
   </div>
</template>
<script>
 import { throttle } from 'lodash'
export default {
  props: ['AreaTree', 'defaultProps', 'fistOrganTreeData', 'handleNodeClickData'],
  data () {
    return {
      data: this.AreaTree,
      defaultPropsTree: this.defaultProps,
      organId: [],
      defaultOrganId: [],
      breadList:[],
      breadNameList:[],
    }
  },
  watch: {
    AreaTree: {
      deep: true,
      handler (newV, oldV) {
        this.data = newV
        // if (this.fistOrganTreeData.defaultExpandedKeys) {
        //   alert(this.fistOrganTreeData.defaultExpandedKeys[0])
        //   this.defaultOrganId[0] = this.fistOrganTreeData.defaultExpandedKeys[0]
        // } else {
        // this.defaultOrganId[0] = // newV[0].organId
        // }
        // this.defaultOrganId[0] = // newV[0].organId
        this.defaultOrganId = this.fistOrganTreeData
        // console.log(9999, this.fistOrganTreeData)
        this.loadNode()
        if (this.handleNodeClickData.organId > 0) {
          this.handleNodeClick(this.handleNodeClickData)
        } else {
          this.handleNodeClick(newV[0])
        }
      }
    } // ,
    // organId: {
    //   deep: true,
    //   handler (newV, oldV) {
    //     // alert(newV)
    //   }
    // }
  },
  // mounted () {
  //   // this.defaultOrganId[0] = this.AreaTree[0].organId
  //   this.loadNode()
  // },
  methods: {
    // 递归函数
    platform(node) {
      // console.info(node)
        if (!node || !node.parent) {// 若无父节点，则直接返回
          return
        }
        this.breadList.unshift(node.data.organId)// 将value保存起来
        this.breadNameList.unshift(node.data.organName)// 将value保存起来
        //调用递归
        this.platform(node.parent)
      },
    handleNodeClick (data,node) {
      // 参数：根据navigateLevel 展示显示到不同级别的树  导航类型 [省 = 1, 供水公司 = 2, 运营处 = 3, 小区 = 11, 楼栋 = 12, 单元 = 13,门牌号 = 14,集中器 = 21]
      // 返回值：类型种类(type==1时1表示省 2表示供水公司  3表示运营处  type==2时kind与villageType保持一致)
      this.breadList =[]
      this.breadNameList =[]
      this.platform(node);
      this.$bus.$emit('leftOrganId', { organId: data.organId, villageType: data.villageType, type: data.type, kind: data.kind, organName: data.organName ,pid:data.pid,organCode:data.organCode,all:this.breadList,allName:this.breadNameList})
      this.organId[0] = data.organId
    },
    loadNode () {
      // this.handleNodeClick(this.fistOrganTreeData)
      const lengthOrgan = this.fistOrganTreeData.length
      let organId = this.fistOrganTreeData[lengthOrgan - 1]
      if (organId === undefined) {
        organId = this.AreaTree[0].organId
        this.defaultOrganId[0] = this.AreaTree[0].organId
      }
      // alert(organId)
      this.$nextTick(function () {
        this.$refs.organTree.setCurrentKey(organId)
      })
    }
  },
  beforeDestroy () {
    this.$bus.$off('leftOrganId')
  }
}
</script>
<style lang="scss" scoped>
.OrgTree {
      margin-top: 20px;
      margin-left: 15px;
      width: 240px;
          height: calc(100vh - 170px);
      overflow: hidden;
      background: url("@/assets/images/public/bgOrgTree.png") no-repeat;
      background-size: 100% 100%;
      h4 {
        font-size: 0.0729rem;
        padding: .13rem .1563rem;
        text-align: left;
      }
      .el-tree {
        background: none;
        padding-left:10px;

       ::v-deep .el-tree-node:focus > .el-tree-node__content {
          background: linear-gradient(
            to right,
            rgba(1, 135, 146, 1),
            rgba(1, 135, 146, 0)
          ); //背景色
        }
        ::v-deep .el-tree-node .el-tree-node__content {
          &:hover {
            background: linear-gradient(
              to right,
              rgba(1, 135, 146, 0.8),
              rgba(1, 135, 146, 0)
            );
          }
        }
        ::v-deep .el-tree-node {
          .el-tree-node__children {
            .el-tree-node:focus > .el-tree-node__content {
              background: linear-gradient(
                to right,
                rgba(1, 135, 146, 0.8),
                rgba(1, 135, 146, 0)
              ); //背景色
              background-position: 10px 10px;
            }
            .el-tree-node .el-tree-node__content {
              &:hover {
                background: linear-gradient(
                  to right,
                  rgba(1, 135, 146, 0.8),
                  rgba(1, 135, 146, 0)
                );
                background-position: 10px 10px;
              }
            }
          }
          .el-tree-node {
            .el-tree-node__children {
              .el-tree-node:focus > .el-tree-node__content {
                background: linear-gradient(
                  to right,
                  rgba(1, 135, 146, 0.6),
                  rgba(1, 135, 146, 0)
                ); //背景色
                background-position: 20px 10px;
              }
              .el-tree-node .el-tree-node__content {
                &:hover {
                  background: linear-gradient(
                    to right,
                    rgba(1, 135, 146, 0.6),
                    rgba(1, 135, 146, 0)
                  );
                  background-position: 20px 10px;
                }
              }
            }
          }
        }
      }
    }
 ::v-deep .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: linear-gradient(
                    to right,
                    rgba(1, 135, 146, 0.6),
                    rgba(1, 135, 146, 0)
                  );
}

</style>
