import request from '@/utils/Api'

/**
 * ********查询网关列表********
 *type                        integer           查询类型          [1.片区 2.档案]
  organId                     integer           片区/档案ID
  gatewayName                 string            网关名称
  address                     string            网关地址
  pageSize                    integer           页大小
  pageIndex                   integer           页码
  */
export const getDGatewayList = (data) => {
  return request({
    url: '/api/devManager/gateway/getGatewayList',
    method: 'POST',
    data
  })
}
/**
 * ********撤销开关阀指令********
 * id  指令的id .
  */
export const cancelGatewayInstruct = (id) => {
  return request({
    url: '/api/devManager/gateway/cancelGatewayInstruct',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * ********查询网关列表********
 *gatewayNo           integer           网关号
  type                integer           关联类型          1关联 2未关联
  condition           string            查询条件          查询"向网关添加表档案"列表时使用
  pageSize            integer           页大小
  pageIndex           string            页码
  */
export const getGatewayMeterList = (data) => {
  return request({
    url: '/api/devManager/gateway/getGatewayMeterList',
    method: 'POST',
    data
  })
}

/**
 * ********添加网关水表关联档案********
 *gatewayNo           integer           网关号
  meterNo             string            水表号
  */
export const addGatewayMeter = (data) => {
  return request({
    url: '/api/devManager/gateway/addGatewayMeter',
    method: 'POST',
    data
  })
}

/**
 * ********设置网关********
 *ip          string        ip
  port        integer       端口
  gatewayNo   string        网关号
*/
export const setGateway = (data) => {
  return request({
    url: '/api/devManager/gateway/setGateway',
    method: 'POST',
    data
  })
}

/**
 * ********设置网关********
 *gatewayNo           string            网关号
  pageSize            integer           页大小
  pageIndex           string            页码
*/
export const getGatewayInstructList = (data) => {
  return request({
    url: '/api/devManager/gateway/getGatewayInstructList',
    method: 'POST',
    data
  })
}

/**
 * ********删除网关水表关联档案********
 *meterNo           string            表号
*/
export const delGatewayMeter = (meterNo) => {
  return request({
    url: '/api/devManager/gateway/delGatewayMeter',
    method: 'get',
    params: {
      meterNo
    }
  })
}
