// 水在线2.0的路由

import Vue from 'vue'
import Router from 'vue-router'
import '@/utils/global'
import { getStorage } from '@/utils/storage' // setStorage
import MainPage from '@/views/MainPage'
import waterMainPage from '@/views/waterCloudPlatform/MainPage'
import Desktop from '@/views/Desktop'
import Layout from '@/views/Layout'
import waterLayout from '@/views/waterLayout'

let routerList = []
routerList = getRouer(Vue.prototype.$storageKey.Router)
if(routerList.length>0){
  console.info(routerList)
  console.info(routerList[0])
  console.info(routerList[0].path)
}

Vue.use(Router)
const router = new Router({
  // mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/Cockpit/Index'
    },
    {
      path: '/cockpit',
      name: 'cockpit',
      redirect: '/Cockpit/Index',
      meta: {
        isShowLetMenu: false
      },
      component: MainPage,
      children: [
        {
          path: 'index',
          name: 'cockpit-index',
          component: () => import('@/views/Cockpit/Index')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Login'),
      // 路由独享守卫
      beforeEnter (to, from, next) {
        if (getStorage(Vue.prototype.$storageKey.TokenKey)?.length > 0) {
          // next(routerList[0].path) // 已经登录返回首页
          if(getStorage('UserInfo') == 'xinle'){
            next(routerList[0].path)
          }else{
            next('/Cockpit/Index')
          }
        } else {
          next()
        }
      }
    },
    {
      path: '*',
      name: '404',
      component: () => import('../views/404')
    },
    {
      path: '/desktop',
      name: 'desktop',
      component: Desktop
    }
    // ...routerList
  ]
})

routerList.forEach((item, index) => {
  router.addRoute(item)
})

// 路由 - 全局前置守卫(在路由发生真正跳转之前执行,决定路由是否跳转/取消/强制中断切换到别的路由
router.beforeEach((to, from, next) => {
  // 需求: 如果你已经登录了, 不要切换到登录页面
  if (getStorage(Vue.prototype.$storageKey.TokenKey)?.length > 0 && to.path === '/login') {
    // 想要进登录页不留在原地了, 而是返回首页
    document.title = '驾驶舱-丰源水在线智控系统'
    // next('/Cockpit/Index')
    // next('/Cockpit/Index')
    // next(routerList[0].path)
    if(getStorage('UserInfo') == 'xinle'){
      next(routerList[0].path)
    }else{
      next('/Cockpit/Index')
    }


  } else if (!getStorage(Vue.prototype.$storageKey.TokenKey) && to.path !== '/login') {
    document.title = '登录-丰源水在线智控系统'
    next('/login')
  } else {
    if (to.path === '/Cockpit/Index') to.meta.title = '驾驶舱'
    if (to.path === '/login') to.meta.title = '登录'
    document.title = to.meta.title + '-丰源水在线智控系统'
    next() // 其他情况通通放行
  }
})

// 防止编程时导航的报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router

function getRouer () {
  let UserMenusLists = [];
   UserMenusLists = getStorage(Vue.prototype.$storageKey.UserMenusList)
   if(!UserMenusLists){
    UserMenusLists=[];
   }
  const routerList = []
  if (UserMenusLists && UserMenusLists.length > 0) {
    UserMenusLists.forEach((item1, index1) => { // 1级 系统
      if(item1.platformType == 2){
        routerList[index1] = {
          path: item1.path,
          redirect: item1.path,
          component: waterLayout,
          meta: {
            title: item1.rightName,
            menuId: item1.rightId
          },
          children: []
        }
      }else{
        routerList[index1] = {
          path: item1.path,
          redirect: item1.path,
          component: Layout,
          meta: {
            title: item1.rightName,
            menuId: item1.rightId
          },
          children: []
        }
      }

      if (item1.children.length) {
        let index2 = 0
        item1.children.forEach((item2) => { // 2级 设置
          if (item2.rightType !== 3) {
            index2++
            routerList[index1].children[index2] = {
              path: item2.path,
              component: loadView(item2.component), //  () => import(`@/views${item2.component}`),
              meta: {
                title: item2.rightName,
                menuId: item2.rightId
              }
            }
          }
          if (item2.children.length) {
            item2.children.forEach((item3) => { // 3级 片区
              if (item3.rightType !== 3) {
                index2++
                routerList[index1].children[index2] = {
                  path: item3.path,
                  component: loadView(item3.component), // () => import(`@/views${item3.component}`),
                  meta: {
                    title: item3.rightName,
                    menuId: item2.rightId
                  }
                }
              }
            })
          }
        })
      }
    })
  }
  return routerList
}

function loadView (view) {
  return () => import(`@/views${view}`)
}
