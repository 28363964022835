import request from '@/utils/Api'

/**
 * 查询表具开通列表
 * PageIndex                      integer                     页码
   PageSize                       integer                     查询条数
   MeterNo                        string                    表具编号
   OrganId                        string                    机构ID
   Level                          string                    机构级别
   Address                        string                    用户地址
   OpenState                      integer                     开通状态（1：已开通，0：未开通）
   Type                           integer                     组织机构类型（1：片区，2：小区）
   MeterType                      string                    表类型（1：lora，2：NB，3：NB分体）
 */
export const getOpenMeterList = (data) => {
  return request({
    url: '/api/archive/meter/getOpenMeterList', // '/api/archive/meter/getOpenMeterList',
    method: 'post',
    data: data
  })
}
