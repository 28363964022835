<!-- 内页的LAYOUT页面 -->
<template>
  <div class="main-page">
    <Menu ></Menu>
    <div class="allContent">
        <leftMenu :leftMenuData="leftMenu"></leftMenu>
        <div class="main"  :style="{'width':!isCollapse?'calc(100% - 200px)':'calc(100% - 64px)'}" style="display: flex;justify-content: space-between;">
          <AreaTree  :AreaTree="AreaTree" :defaultProps="defaultProps" :fistOrganTreeData="fistOrganTreeData" :handleNodeClickData="handleNodeClickData" v-show="isShowAreaTree"></AreaTree>
          <router-view @getFistOrganTreeData="getFistOrganTreeData" :topMenu="topMenu"/>
       </div>

    </div>
  </div>
</template>

<script>
import Menu from '@/components/menu/Menu'
import leftMenu from '@/components/pubilc/leftMenu'
import AreaTree from '@/components/pubilc/AreaTree'
import { getNavigateTree2 } from '@/ApI/index'
export default {
  components: { Menu, leftMenu, AreaTree },
  name: 'layout',
  data () {

    return {

      isCollapse:false,
      topMenu: [],
      leftMenuId: '', // // 第几个一级目录（用来确定二级目录）
      leftMenu: [],
      isShowAreaTree: true, // 是否显示树
      navigateLevel: 1,
      AreaTree: [], // 区域树数据
      defaultProps: { // el 树显示参数
        children: 'children',
        label: 'organName'
      },
      dialogFormVisible: false,
      firstOrganTreeLevel: [],
      defaultOrganId: 0,
      handleNodeClickData: {}, // 默认被选中的区域树的数据
      fistOrganTreeData: {} // 默认展开及选中的节点
    }
  },
  mounted () {

    if (!this.AreaTree) {
      this.getOrganTree(13)
    }

  },
  watch: {
    leftMenuId () {
      this.getLeftMenu()
    }
  },
  methods: {
    // 获取认展开及选中的节点
    getFistOrganTreeData (organId) {
      if (organId > 0) {
        this.defaultOrganId = organId
      }
    },
    // 参数： 根据navigateLevel 展示显示到不同级别的树  导航类型 [省 = 1, 供水公司 = 2, 运营处 = 3, 小区 = 11, 楼栋 = 12, 单元 = 13,门牌号 = 14,集中器 = 21]
    // 返回值： 类型种类(type==1时1表示省 2市 3表示供水公司  4表示运营处  kind==1时是小区2是楼栋、3是单元 kind与villageType保持一致)
    async getOrganTree (navigateLevel) {
      const { data: res } = await getNavigateTree2(navigateLevel)
      if (res.success) {
        this.AreaTree = res.data
        this.isClickFirstOrganTreeData(this.defaultOrganId)
        this.AreaTree.forEach(item => {
          this.firstOrganTreeLevel.push(item.organId)
        })
      }
    },
    getLeftMenu () {
      this.leftMenu = []
      const leftMenu = this.topMenu[this.leftMenuId].children
      // leftMenu.sort((a, b) => { return a.order - b.order })
      leftMenu.forEach(item => { // 2 级
        if (item.rightType !== 3) {
          // this.leftMenu.push(item)
          const childrenM = this.isChildren(item.children)
          if (childrenM.length > 0) {
            item.childrenM = childrenM
          }
          this.leftMenu.push(item)
        }
      })
    },
    // 递归遍历
    isChildren (data) {
      const children = []
      if (data.length > 0) {
        data.forEach(item => {
          if (item.rightType !== 3) {
            children.push(item)
          }
        })
      }
      return children
    },
    changeIsShowAreaTree (isShouw) {
      this.isShowAreaTree = isShouw
    },
    // 是否默认选中第一个区域
    isClickFirstOrganTreeData (organId = 0) {
      this.fistOrganTreeData = []
      if (organId > 0) {
        // 遍历多维数组（区域树）
        this.TraversalObject(this.AreaTree, organId)
      } else {
        this.fistOrganTreeData.push(this.AreaTree[0].organId)
      }
    },
    // 递归获取区域树需要展开选中的数据
    // obj        要遍历的数组
    // organId    要查找的元素
    // key        上一级的organId
    TraversalObject (obj, organId, key = '') {
      if (this.firstOrganTreeLevel.includes(organId)) {
        // console.log('afafadsf', this.fistOrganTreeData)
        return this.fistOrganTreeData
      }
      obj.forEach(item => {
        if (item.organId === organId) {
          this.fistOrganTreeData.unshift(organId)
          this.TraversalObject(this.AreaTree, key) // 查询上一级
          this.handleNodeClickData = item
          // console.log('最终：', item, key)
        } else {
          if (item.children) {
            this.TraversalObject(item.children, organId, item.organId)
          }
        }
      })
      // for (const ad in obj) {
      //   console.log('dddddd', ad)
      //   if (typeof (obj[ad]) === 'object') {
      //     this.TraversalObject(obj[ad]) // 递归遍历
      //   }
      // TypeError: Cannot read properties of undefined (reading 'status')
      // else {
      //   // alert(ad + '=' + obj[ad]) // 值就显示
      //   console.log(ad + organId + '=' + obj[ad])
      // }
      // }
    }

  }

}
</script>

<style lang="scss" >
.allContent{
  display: flex;
  width: 100%;
  min-width: 1384px;

}
 .el-dialog{
    background: #015184 !important;
    border: 2px #07b7d3 solid !important;
    border-radius: .0521rem !important;
    box-shadow: 0 0 16px rgba(12,118,182, 1) !important;
    color: #fff;
    overflow: auto;
    width: aout;
    min-width: 432px;
    .el-dialog__title{
      color: #fff;
    }
    .el-form-item{
      .el-form-item__label{
        color: #fff;
      }
      input {
        width: 85%;
      }
    }
  }

.el-cascader-panel{
    .el-cascader-menu{
    .el-cascader-node{
      .el-cascader-node__label{
      color: #565656 !important;
      }
    }
  }
}
</style>
