import request from '@/utils/Api'
/**
 * ********添加业主信息********
 *  pid                             integer                   单元ID
    ownerCode                       string                    户号
    doorplate                       string                    门牌号
    linkMan                         string                    联系人
    tel                             string                    电话
    address                         string                    详细地址
    meterType                       integer                   水表类型
    meterVender                     string                    运营商                    [电信,联通,移动]
    isValve                         integer                   是否阀控                  0.不带阀控 1。带阀控
    installDate                     string                    安装日期                  只有日期部分 比如2022-03-18
    meterNo                         string                    表号
    collectorId                     integer                   集中器ID
    hostId                          integer                   采集器ID
    meterBaseNum                    integer                   表底数
    useWaterType                    integer                   用水类型
    deviceId                        string                    设备Id
    runCode                         string                    运营编码
    payMode                         integer                   缴费方式                  1.预付费 2.后付费
  */
export const addOwner = (data) => {
  return request({
    url: '/api/archive/village/addOwner', // '/api/system/village/addOwner',
    method: 'POST',
    data
  })
}

/**
 * ********业主信息********
 *  ownerId                             integer                   业主ID
    meterId                             integer                   水表ID
    unitId                              integer                   单元ID
    ownerCode                           string                    户号
    doorplate                           string                    门牌号
    linkMan                             string                    联系人
    tel                                 string                    电话
    address                             string                    地址
    meterType                           integer                   水表类型
    meterVender                         string                    运营商
    isValve                             integer                   是否阀控              1阀控 0不阀控
    installDate                         string                    安装时间
    meterNo                             string                    水表表号
    collectorId                         integer                   收集器Id
    hostId                              integer                   采集器ID
    meterBaseNum                        integer                   水表表底数
    useWaterType                        integer                   用水类型
    deviceId                            string                    设备ID
    runCode                             string                    运营编码
  */
export const editOwner = (data) => {
  return request({
    url: '/api/archive/village/editOwner', // '/api/system/village/editOwner',
    method: 'POST',
    data
    // data:{
    //     "ownerId": data.ownerId,
    //     "meterId": data.meterId,
    //     "unitId":data.unitId,
    //     "ownerCode": data.ownerCode,
    //     "doorplate": data.doorplate,
    //     "linkMan": data.linkMan,
    //     "tel": data.tel,
    //     "address": data.address,
    //     "meterType": data.meterType,
    //     "meterVender": data.meterVender,
    //     "isValve": data.isValve,
    //     "installDate": data.installDate,
    //     "meterNo": data.meterNo,
    //     "collectorId": data.collectorId,
    //     "hostId": data.hostId,
    //     "meterBaseNum": data.meterBaseNum,
    //     "useWaterType":data.useWaterType,
    //     "deviceId": data.deviceId,
    //     "runCode": data.runCode,
    //     "payMode": data.payMode,
    //     "allowValveOpen": data.allowValveOpen,
    //   }
  })
}

/**
 * ********查询业主信息详细********
 * * gatewayId      query       integer     是     示例值：      1      说明：业主ID
  */
export const getOwner = (ownerId) => {
  return request({
    url: '/api/archive/village/getOwner', // '/api/system/village/getOwner',
    method: 'GET',
    params: {
      ownerId
    }
  })
}

/**
 * ********删除业主信息********
 * * ownerIds      query       integer     是     示例值：      1      说明：网关ID
  */
export const deleteOwners = (ownerIds) => {
  return request({
    url: '/api/archive/village/deleteOwners', // '/api/system/village/deleteOwners',
    method: 'GET',
    params: {
      ownerIds
    }
  })
}

/**
 * ********查询业主列表********
 *  ownerCode                     string                    户号
    linkMan                       string                    联系人
    tel                           string                    电话
    meterNo                       string                    表号
    meterType                     string                    水表类型
    type                          string                    片区类型                      1片区 2小区
    organId                       integer                   片区ID/档案ID
*/
export const getOwnerList = (data) => {
  return request({
    url: '/api/archive/village/getOwnerList', // '/api/system/village/getOwnerList',
    method: 'POST',
    data
  })
}
export const getOwnerList2 = (data) => {
  return request({
    url: '/api/archive/village/getOwnerList2', // '/api/system/village/getOwnerList',
    method: 'POST',
    data
  })
}

/**
 * ********业主档案导出********
 *  type                      integer                   片区类型                  0全部 ,1片区,2小区
    organId                   integer                   片区ID                    当type=0，该值填0
    address                   string                    网关地址
    collectorName             string                    网关名称
*/
export const exportOwnerList = (data) => {
  return request({
    url: '/api/archive/village/exportOwnerList', // '/api/system/village/exportOwnerList',
    method: 'POST',
    data
  })
}
/**
 * ********集中器下拉列表********
*/
export const getCollectorSelectListItemList = (data) => {
  return request({
    url: '/api/archive/collector/getCollectorSelectListItemList', // '/api/system/collector/getCollectorSelectListItemList',
    method: 'POST',
    data
  })
}
/**
 * ********采集器下拉列表********
*/
export const getHostSelectListItemList = (data) => {
  return request({
    url: '/api/archive/host/getHostSelectListItemList', // '/api/system/host/getHostSelectListItemList',
    method: 'POST',
    data
  })
}
/**
 * ********用水类型下拉列表********
*/
export const GetUseWaterTypeSelectListItemList = (data) => {
  return request({
    url: '/api/system/waterPrice/GetUseWaterTypeSelectListItemList',
    method: 'POST',
    data
  })
}

/**
 * ********过户********
 *  LinkMan       string      联系人
    NewCode       string      新户号
    NewPhone      string      联系电话
    ownerId       string      数据主键
    MeterNo       string      表号
*/
export const ChangeOwner = (data) => {
  return request({
    url: '/api/archive/village/ChangeOwner', // '/api/system/village/ChangeOwner',
    method: 'POST',
    data
  })
}
/**
 * ********业主销户列表********
 *  PageIndex   query         integer       否         示例值：        1       说明：      页码
    PageSize    query         integer       否         示例值：        10        说明：        条数
    OrganId     query         integer       否         示例值：        3       说明：      营业处ID
    Type        query         integer       否         示例值：        2       说明：      查询类型[1.片区 2.档案]
    OwnerCode   query         string        否         户号
    LinkMan     query         string        否         联系人
    Tel         query         string        否         联系电话
*/
export const GetOwnerCancelList = (data) => {
  return request({
    url: '/api/archive/village/GetOwnerCancelList', // '/api/system/village/GetOwnerCancelList',
    method: 'GET',
    params: {
      PageIndex: data.PageIndex,
      PageSize: data.PageSize,
      OrganId: data.organId,
      Type: data.type,
      OwnerCode: data.ownerCode,
      LinkMan: data.linkMan,
      Tel: data.tel
    }
  })
}

/**
 * ********生成业主户号********
**/
export const genOwnerCode = () => {
  return request({
    url: '/api/archive/village/genOwnerCode',
    method: 'GET'
  })
}
