import theAxios from 'axios'
import Vue from 'vue'
import { getStorage, removeStorageAll } from '@/utils/storage'
import '@/utils/global'
// import router from '@/router'
const axios = theAxios.create({
  baseURL: Vue.prototype.$storageKey.baseURL, // Vue.prototype.$storageKey.baseURL, // 'http://82.156.216.152:3039', // 'http://foryon.ngrok.88service.com/', // http://192.168.20.34:9000/', // 'http://192.168.20.36:8992', 'http://192.168.20.104:3038', //
  timeout: 180000 // 3分钟超时时间
})

// 用于存储pending的请求（处理多条相同请求）
const pendingRequest = new Map()
// 生成request的唯一key
const generateRequestKey = (config = {}) => {
  // 通过url，method，params，data生成唯一key，用于判断是否重复请求
  // params为get请求参数，data为post请求参数
  const { url, method, params, data } = config
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&')
}
// 将重复请求添加到pendingRequest中
const addPendingRequest = (config) => {
  const key = generateRequestKey(config)
  if (!pendingRequest.has(key)) {
    config.cancelToken = new theAxios.CancelToken(cancel => {
      pendingRequest.set(key, cancel)
    })
  }
}

// 取消重复请求
const removePendingRequest = (config) => {
  const key = generateRequestKey(config)
  if (pendingRequest.has(key)) {
    const cancelToken = pendingRequest.get(key)
    // console.log('ddd', cancelToken)
    cancelToken(key)
    // 取消之前发送的请求
    pendingRequest.delete(key) // 请求对象中删除requestKey
  }
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  if (getStorage(Vue.prototype.$storageKey.TokenKey)?.length > 0 && config.headers.Authorization === undefined) {
    config.headers.Authorization = `Bearer ${getStorage(Vue.prototype.$storageKey.TokenKey)}`
  }
  removePendingRequest(config)
  addPendingRequest(config)
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // Vue.prototype.$message.success(response.data.message)
  removePendingRequest(response.config)
  return response
}, async function (error) { // async
  if (error.response?.status) {
    switch (error.response.status) {
      case 401:
        Vue.prototype.$message.error('401.您尚未登录或参数错误，请重新登录！')
        removeStorageAll()
        setTimeout(() => {
          window.location.href = '/#/Login'
        }, 1600)
        break
      case 403:
        Vue.prototype.$message.error('403.登录超时，请重新登录！')
        removeStorageAll()
        setTimeout(() => {
          window.location.href = '/'
        }, 1600)
        break
      case 404:
        Vue.prototype.$message.error('404.未找到页面或您没有该权限！')
        break
      case 415:
        Vue.prototype.$message.error('415.参数或格式错误！')
        break
      case 500:
        Vue.prototype.$message.error('500.服务器开小差了')
        break
      default:
        Vue.prototype.$message.error(error)
    }
  }
  removePendingRequest(error.config || {})
  // Vue.prototype.$message.error(error)
  return Promise.reject(error)
})

export default ({ url, method = 'POST', params = {}, data = {}, headers = {} }) => axios({
  url,
  method,
  params,
  data,
  headers
})
