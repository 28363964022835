import request from '@/utils/Api'
/**
 * ********查询业主信息列表********
 *  ownerCode                     string                    户号
    linkMan                       string                    联系人
    tel                           string                    电话
    organId                       integer                   片区ID/档案ID
    bindStatus                    integer                   绑定水表状态                  1绑定 0未绑定 -1全部
    type                          integer                   片区类型                      [1.片区 2.档案] 0全部
    pageSize                      integer                   也大小
    pageIndex                     integer                   页码
*/
export const getOwnerOnlyList = (data) => {
  return request({
    url: '/api/archive/village/getOwnerOnlyList', // '/api/system/village/getOwnerOnlyList',
    method: 'POST',
    data
  })
}
export const getOwnerOnlyList2 = (data) => {
  return request({
    url: '/api/archive/village/getOwnerOnlyList2', // '/api/system/village/getOwnerOnlyList',
    method: 'POST',
    data
  })
}
/**
 * ********添加业主信息********
 *  unitId              integer           单元ID
    ownerCode           string            户号
    doorplate           string            门牌号
    linkMan             string            联系人
    tel                 string            电话
    address             string            地址
  */
export const addOwnerOnly = (data) => {
  return request({
    url: '/api/archive/village/addOwnerOnly', // '/api/system/village/addOwnerOnly',
    method: 'POST',
    data
  })
}

/**
 * ********业主信息********
 *  ownerId               integer             业主ID
    unitId                integer             单元ID
    ownerCode             string              业主户号
    doorplate             string              门牌号
    linkMan               string              联系人
    tel                   string              电话
    address               string              地址
  */
export const editOwnerOnly = (data) => {
  return request({
    url: '/api/archive/village/editOwnerOnly', // '/api/system/village/editOwnerOnly',
    method: 'POST',
    data
  })
}

/**
 * ********查询业主信息详细********
 * * ownerId      query       integer     是     示例值：      1      说明：业主ID
  */
export const getOwnerOnly = (ownerId) => {
  return request({
    url: '/api/archive/village/getOwnerOnly', // '/api/system/village/getOwnerOnly',
    method: 'GET',
    params: {
      ownerId
    }
  })
}

/**
 * ********删除业主信息********
 * * ownerIds      query       integer     是     示例值：      1      说明：网关ID
  */
export const deleteOwnersOnly = (ownerIds) => {
  return request({
    url: '/api/archive/village/deleteOwnersOnly', // '/api/system/village/deleteOwnersOnly',
    method: 'GET',
    params: {
      ownerIds
    }
  })
}

/**
 * ********导出业主列表********
 *  参数同 getOwnerOnlyList
*/
export const getOwnerOnlyListExportData = (data) => {
  return request({
    url: '/api/archive/village/getOwnerOnlyListExportData', // '/api/system/village/ChangeOwner',
    method: 'POST',
    data
  })
}
