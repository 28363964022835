import request from '@/utils/Api'

/**
 * ********查询水表控制列表********
 *type                        integer           查询类型          [1.片区 2.档案]
  organId                     integer           片区/档案ID
  ownerCode                   string            户号
  linkMan                     string            联系人
  tel                         string            电话
  meterNo                     string            表号
  meterType                   integer           表类型            0全部
  pageIndex                   integer
  pageSize                    integer
  valveType                   integer           阀控类型          0全部 1无阀控 2有阀控)
  remainMoneyStatus           integer           剩余水费状态          (0全部 1欠费 2正常)
  */
export const getMeterControlList = (data) => {
  return request({
    url: '/api/devManager/meter/getMeterControlList',
    method: 'POST',
    data
  })
}
/**
 * ********控阀********
 *meterNo                     string                水表号
  valveStatus                 string                阀控状态                0开阀 1关阀
  */
export const openCloseValve = (data) => {
  return request({
    url: '/api/devManager/meter/openCloseValve',
    method: 'POST',
    data
  })
}
/**
 * ********水表阀门变化记录********
 *meterNo                     string                    水表号
  pageSize                    integer                   页大小
  pageIndex                   integer                   页码
  */
export const GetValveChangeLogList = (data) => {
  return request({
    url: '/api/devManager/meter/GetValveChangeLogList',
    method: 'POST',
    data
  })
}
/**
 * ********撤销开关阀指令********
 * id  指令的id .
  */
export const valveCancel = (id) => {
  return request({
    url: '/api/devManager/meter/valveCancel',
    method: 'GET',
    params: {
      id
    }
  })
}
export function BatchOpenCloseValve(params){
  return request({
    url: '/api/devManager/Meter/BatchOpenCloseValve',
    method: 'post',
    data:params
  })
}
