import request from '@/utils/Api.js'
/*
  ***添加角色***
  roleName        string        角色名
  remark          string        备注
*/
export const addRole = (data) => {
  const obj = data
  return request({
    url: '/api/system/role/addRole',
    method: 'POST',
    data: obj
  })
}

/*
  ***编辑角色***
  roleId          integer       角色ID
  roleName        string        角色名
  remark          string        备注                                 null
*/
export const editRole = (data) => {
  const obj = data
  return request({
    url: '/api/system/role/editRole',
    method: 'POST',
    data: obj
  })
}

/* ***查询角色信息*** */
export const getRole = (roleId) => {
  return request({
    url: '/api/system/role/getRole',
    method: 'GET',
    params: {
      roleId: roleId
    }
  })
}

/* ***删除角色信息*** */
export const deleteRoles = (roleId) => {
  return request({
    url: '/api/system/role/deleteRoles',
    method: 'GET',
    params: {
      roleIds: roleId
    }
  })
}

/*
  ***角色列表***
  pageSize          integer             条数
  pageIndex         integer             当前页
*/
export const getRoleList = (data) => {
  const obj = data
  return request({
    url: '/api/system/role/getRoleList',
    method: 'POST',
    data: obj
  })
}

/* ***查询角色关联权限列表*** */
export const getRoleRelationRightList = (roleId) => {
  return request({
    url: '/api/system/role/getRoleRelationRightList',
    method: 'GET',
    params: {
      roleId: roleId
    }
  })
}

/*
  ***设置角色权限授权***
  roleId          integer
  rightIds        array[integer]        权限ID数组
*/
export const setRoleRelationRight = (data) => {
  return request({
    url: '/api/system/role/setRoleRelationRight',
    method: 'POST',
    data
  })
}

/*
  ***查询角色下拉列表***
  roleId          integer               角色ID
*/
export const getRoleSelectListItemList = (roleId) => {
  return request({
    url: '/api/system/role/getRoleSelectListItemList',
    method: 'GET',
    params: {
      roleId: roleId
    }
  })
}

/*
  ***查询角色关联数据树形列表***
  roleId          integer               角色ID
*/
export const getRoleRelationOrganList = (roleId) => {
  return request({
    url: '/api/system/role/getRoleRelationOrganList',
    method: 'GET',
    params: {
      roleid: roleId
    }
  })
}

/*
  ***设置角色关联的片区***
  roleId
  organList[{organId,type}]
*/
export const SetRoleRelationOrgan = (data) => {
  return request({
    url: '/api/system/role/SetRoleRelationOrgan',
    method: 'POST',
    data
  })
}
