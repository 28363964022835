import request from '@/utils/Api.js'
/*
  ***添加用户***
  userName        string              用户名称
  password        string              密码
  realName        string              真实姓名                                  null
  roleIds         array[string]       角色ID
  provinceId      string              所在省ID
  companyId       string              供水公司ID
  orgId           string              机构ID
  status          integer             状态             1正常 0无效
  remark          string              备注信息                                  null
*/
export const addUser = (data) => {
  const obj = data
  return request({
    url: '/api/system/user/addUser', // '/api/system/user/AddUser',
    method: 'POST',
    data: obj
  })
}

/*
  ***编辑用户***
  userId          integer             用户ID
  userName        string              用户名称
  password        string              密码
  realName        string              真实姓名                                  null
  roleIds         array[string]       角色ID
  provinceId      string              所在省ID
  companyId       string              供水公司ID
  orgId           string              机构ID
  status          integer             状态             1正常 0无效
  remark          string              备注信息                                  null
*/
export const EditUser = (data) => {
  const obj = data
  return request({
    url: '/api/system/user/editUser', // '/api/system/user/EditUser',
    method: 'POST',
    data: obj
  })
}

/* ***查询用户信息*** */
export const GetUser = (userId) => {
  return request({
    url: '/api/system/user/getUser', // '/api/system/user/GetUser',
    method: 'GET',
    params: {
      userid: userId
    }
  })
}

/* ***删除用户信息*** */
export const deleteUsers = (userId) => {
  return request({
    url: '/api/system/user/deleteUsers', // '/api/system/user/deleteUsers',
    method: 'GET',
    params: {
      userIds: userId
    }
  })
}

/*
  ***用户列表***
  pageSize          integer             用户ID
  pageIndex         integer             用户名称
*/
export const getUserList = (data) => {
  const obj = data
  return request({
    url: '/api/system/user/getUserList', // '/api/system/user/getUserList',
    method: 'POST',
    data: obj
  })
}
