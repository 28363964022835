/*
 * @Author: 'sugang' '894141821@qq.com'
 * @Date: 2023-04-07 15:01:42
 * @LastEditors: 'sugang' '894141821@qq.com'
 * @LastEditTime: 2023-09-18 14:54:08
 * @FilePath: \FY.WaterSystem.UI\src\utils\global.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
const storageKey = {
  TokenKey: 'Authorization',
  UserInfo: 'UserInfo',
  UserMenusList: 'UserMenusList',
  Roles: 'Roles',
  Router: 'Router',
  leftMenuId: 'leftMenuId',
  allButton: 'allButton',
//baseURL:'http://192.168.20.104:5038', //测试
baseURL:'http://water.foryonszx.com:6006', //正式
//baseURL: 'http://192.168.20.46:3041',//解工IP

  //测试服务器 控阀
  baseURL2: 'http://192.168.20.104:3040',// 'http://water.foryonszx.com:6006',

  GatewayTemplate: '/api/system/content/excel/template/网关档案导入模板.xlsx', // '/api/system/content/excel/template/网关档案导入模板.xlsx',
  villageTemplate: '/api/system/content/excel/template/水在线-档案导入模板.xlsx', // 小区档案模板
  ownerInfoTemplate: '/api/system/content/excel/template/业主档案导入模板.xlsx', // 业主信息 = 》 业主档案导入模板
  MeterTemplate: '/api/system/content/excel/template/水表档案导入模板.xlsx', // 水表档案模板
  CollectorTemplate: '/api/system/content/excel/template/集中器档案导入模板.xlsx', // 档案管理下
  HostTemplate: '/api/system/content/excel/template/采集器档案导入模板.xlsx', // 档案管理下
  upGatewayFileUrl: '/api/archive/gateway/importGatewayData', // '/api/system/gateway/importGatewayData', // 网关上传地址
  upVillageFileUrl: '/api/archive/village/importVillageData', // '/api/system/village/importVillageData',  导入小区档案
  upOwnerDataFileUrl: '/api/archive/village/importOwnerData', // '/api/system/village/importVillageData'  业主信息 = 》 导入导入业主信息
  upMeterDataFileUrl: '/api/archive/meter/importMeterData', // '/api/system/village/importVillageData'  业主信息 = 》 导入导入业主信息
  upCollectorFileUrl: '/api/archive/collector/importCollectorData', //   档案管理导入集中器
  upHostFileUrl: '/api/archive/host/importHostData' //   档案管理导入采集中器
}
// 按钮
const buttonKey = {
  organ: ['addToOrgan1', 'addToOrgan2', 'addToOrgan3', 'editToOrgan', 'delToOrgan'],
  waterPrice: ['addToWaterPrice', 'addTypeToWaterPrice', 'editToWaterPrice', 'delToWaterPrice'],
  SysParamsSet: ['editToSysParamsSet'],
  User: ['editToUser', 'delToUser', 'addToUser'],
  Role: ['addToRole', 'toData', 'toRole', 'delToRole', 'editToRole'],
  Collector: ['exportToCollector', 'addToCollector', 'editToCollector', 'delToCollector', 'downloadToCollector', 'importToCollector'],
  Owner: ['addToOwner', 'exportToOwner', 'editToOwner', 'delToOwner', 'bindToOwner', 'changeToOwner'],
  OwnerInfo: ['addToOwnerInfo', 'editToOwnerInfo', 'bindToOwnerInfo', 'delToOwnerInfo', 'exportToOwnerInfo', 'downloadToOwnerInfo', 'importToOwnerInfo'],
  Meter: ['addToMeter', 'bindToMeter', 'editToMeter', 'delToMeter', 'exportToMeter', 'importToMeter', 'downloadToMeter'],
  ChangeMeter: ['ChangeToMeter'],
  Host: ['exportToHost', 'addToHost', 'editToHost', 'delToHost', 'downloadToHost', 'importToHost'],
  Village: ['addToVillage', 'exportToVillage', 'importToVillage', 'downloadToVillage', 'add2ToVillage', 'editToVillage', 'delToVillage'],
  Gateway: ['exportToGateway', 'importToGateway', 'downloadToGateway', 'addToGateway', 'editToGateway', 'delToGateway'],
  RealData: ['exportToRealData', 'assembleReadMeter', 'batchReadMeter', 'singleReadMeter', 'electronSingleReadMeter'],
  MeterReadData: ['exportToMeterReadData'],
  GatewaySet: ['addToGatewaySet', 'listToGatewaySet', 'setToGatewaySet', 'instructionToGatewaySet'],
  CollectorSet: ['lineA1ToCollectorSet', 'lineA2ToCollectorSet', 'lineA3ToCollectorSet', 'lineB1ToCollectorSet', 'lineB2ToCollectorSet', 'lineB3ToCollectorSet', 'lineB4ToCollectorSet', 'lineC1ToCollectorSet', 'lineC2ToCollectorSet', 'lineC3ToCollectorSet', 'lineC4ToCollectorSet', 'lineC5ToCollectorSet', 'setToCollectorSet'],
  ElectronRemoteTransmission: ['setCollectorTime', 'getCollectorTime', 'startInspect1', 'startInspect2', 'startReadMeter', 'readAllMeters', 'readSingleMeter', 'getCmdResult', 'setToElectronRemoteTransmission'],
  ScheduleSet: ['addToScheduleSet', 'editToScheduleSet', 'delToScheduleSet'],
  MeterControl: ['instructionToMeterControl', 'openToMeterControl', 'closeToMeterControl'],
  SystemLog: ['SystemLog'],
  OperationLog: ['OperationLog']
}
Vue.prototype.$storageKey = storageKey
Vue.prototype.$buttonKey = buttonKey
