const mixin = {
  data () {
    return {
      menuId: this.$route.meta.menuId,
      top: [
        { color: '#00D6C1', border: '#00cfc3', shadow: '#4effe8', bg: { start: '#00A89E', end: '#09667F' }, number: '527,819', title: '已安装水表总数' },
        { color: '#17E9FA', border: '#12c8e1', shadow: '#12c8e1', bg: { start: '#22a0e8', end: '#16419a' }, number: '218,233', title: '在线数' },
        { color: '#DBABFF', border: '#C77EFF', shadow: '#a300e7', bg: { start: '#ae54ed', end: '#592ca4' }, number: '18,565', title: '水表异常数量' },
        { color: '#4FC3FF', border: '#017ab3', shadow: '#0032dd', bg: { start: '#1c3cd9', end: '#0f4b8e' }, number: '357,017', title: '缴费总金额' }
      ],
      allMeterType: [
        { text: '全部', value: 0 },
        { text: 'LORA', value: 1 },
        { text: 'NB', value: 2 },
        { text: 'NB分体', value: 3 },
        { text: '电子远传', value: 4 },
        { text: '光电直读', value: 5 }
      ],
      allMeterType4Lei: [
        { text: 'LORA', value: 1 },
        { text: 'NB', value: 2 },
        { text: 'NB分体', value: 3 },
        { text: '电子远传', value: 4 },
         { text: '光电直读', value: 5 }
      ],
      allMeterVender: [
        { text: '电信', value: '电信' },
        { text: '联通', value: '联通' },
        { text: '移动', value: '移动' }
      ],
      allBindStatus: [
        { text: '全部', value: -1 },
        { text: '已绑定', value: 1 },
        { text: '未绑定', value: 0 }
      ],
      collectorType: [
        { text: '电子远传', value: 2 },
        { text: 'LORA', value: 1 },
		{ text: '水表采集集中器', value: 3 },
     { text: '光电直读', value: 4 }
      ],
      allCollectorType: [
        { text: '全部', value: '' },
        { text: '电子远传', value: 2 },
        { text: 'LORA', value: 1 },
        { text: '水表采集集中器', value: 3 },
         { text: '光电直读', value: 4 }
      ],
      page: {
        pageIndex: 1,
        pageSize: 10,
        dateTotal: 0
      },
      loading: false,
      tableData: [],
      loadingTableData: false,
      form: {},
      actionType: 'add',
      dialogFormVisible: false,
      FormLabelWidth: '120px',
      dialogTitle: '',
      sortInstallDate: 0, // 安装时间排序 0不排序 1正序 -1倒序
      pageButton: this.$buttonKey, // 本页的所有有权限的按钮,
      villageList: [{ text: '请选择', value: '' }], // 片区下的村、小区 { text: '请选择', value: '' }
      buildingList: [{ text: '请选择', value: '' }], // 楼栋
      unitList: [{ text: '请选择', value: '' }], // 单元
      collectorList: [{ text: '请选择', value: '' }]
    }
  },
  mounted () {
    this.$bus.$emit('menuId', this.menuId) // 点击一级菜单进入、设置默认的二级菜单高亮
  },
  beforDestroy () {
    this.$bus.$off('menuId') // 当这个组件销毁的时候bus也跟着一起销毁
  },
  methods: {
    checkFormIsEmpty (message, item, getFocus) {
      if (item === '') {
        this.$message.warning(message)
        this.$refs[getFocus].focus()
      } else {
        return true
      }
    },
    arrToTree  (arr) {
      // 权限结构树 定义目标数组
      const obj = []
      // 循环原始数据
      for (const i in arr) {
        // 如果当前索引值的pid为空，那么就代表当前的索引值为“根级”数据，那么直接push到目标数组里
        if (!arr[i].parentID) {
          obj.push(arr[i])
        } else {
          /**
         * 当前索引值的pid不为空时，
         * 因为当前的pid就相当于当前元素上一级的id
         * 所以要找到原始数据中 哪个item的id等于当前的pid，
         * 假设我们找到的元素叫item，那么item就是当前索引值的父级
         * 所以item.child 就等于当前的索引值。
         * 当我们在原始数据中心找到对应的item，为其设置了child后
         * 目标数组obj中对应的item也会同时修改，因为JS中对象的为引用类型，一个地方修改，所有引用的地方都修改
         */
          const item = arr.find(element => element.uid === arr[i].parentID)
          if (item.childlist) {
            item.childlist.push(arr[i])
          } else {
            item.childlist = []
            item.childlist.push(arr[i])
          }
        }
      }
      return obj
    }
  }
}
export default mixin
