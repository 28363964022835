import request from '@/utils/Api.js'
/*
  ***添加水价1***
  useWaterType                string                用水类型
  volume1                     number                一阶水量
  price1                      number                一阶单价
  volume2                     number                二阶水量
  price2                      number                二阶单价
  volume3                     number                三阶水量
  price3                      number                三阶单价
  companyId                   integer               供水公司ID
  status                      integer               启用状态              0停用 1启用
  remark                      string                备注
*/
export const addWaterPrice = (data) => {
  const obj = data
  return request({
    url: '/api/system/waterPrice/addWaterPrice',
    method: 'POST',
    data: obj
  })
}

/*
  ***添加水价2（添加阶梯水价(现有类型)）***
  id                query         integer         否        示例值：        1     说明：水价ID
  companyId         query         string          否        说明：供水公司ID
*/
export const addWaterPriceById = (data) => {
  const obj = data
  return request({
    url: '/api/system/waterPrice/addWaterPriceById',
    method: 'GET',
    params: {
      id: obj.id,
      companyId: obj.companyId
    }
  })
}

/*
  ***编辑用户***
  waterPriceId                integer               水价ID
  useWaterType                string                用水类型
  volume1                     number                一阶水量
  price1                      number                一阶单价
  volume2                     number                二阶水量
  price2                      number                二阶单价
  volume3                     number                三阶水量
  price3                      number                三阶单价
  companyId                   integer               供水公司ID
  status                      integer               启用状态              0停用 1启用
  remark                      string                备注
*/
export const eidtWaterPrice = (data) => {
  const obj = data
  return request({
    url: '/api/system/waterPrice/eidtWaterPrice',
    method: 'POST',
    data: obj
  })
}

/* ***删除水价信息***
  ids   query   string   是   示例值：   2,3  说明：阶梯水价ID字符串,多个Id之间使用“,”分割
*/
export const deleteWaterPrices = (ids) => {
  return request({
    url: '/api/system/waterPrice/deleteWaterPrices',
    method: 'GET',
    params: {
      ids
    }
  })
}

/*
  ***查询水价列表***
  organId               integer             片区ID            0全部 1省Id 2供水公司ID
  pageSize              integer             页大小
  pageIndex             integer             页码
*/
export const getWaterPriceList = (data) => {
  const obj = data
  return request({
    url: '/api/system/waterPrice/getWaterPriceList',
    method: 'POST',
    data: obj
  })
}
/*
  ***查询水价列表***
  organId               integer             片区ID            0全部 1省Id 2供水公司ID
  pageSize              integer             页大小
  pageIndex             integer             页码
*/
export const getWaterPriceTypeList = (data) => {
  const obj = data
  return request({
    url: '/api/system/waterPrice/getWaterPriceTypeList',
    method: 'POST',
    data: obj
  })
}
