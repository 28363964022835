import request from '@/utils/Api.js'
/*
  ***系统参数设置***
  CompanyId                                   integer                       公司ID
  isOweMoneyCloseValve                        boolean                       是否启用欠费自动关阀
  isRechargeOpenValve                         boolean                       是否启用缴费自动开阀
  isMsgNotice                                 boolean                       是否启用短信通知
  noticeBalance                               number or null                      限定提醒金额
  isOweMoneyNotice                            boolean                       是否启用欠费短信通知
  isRechargeNotice                            boolean                       是否启用充值短信通知
*/
export const sysParamsSet = (data) => {
  return request({
    url: '/api/system/systemSet/sysParamsSet',
    method: 'POST',
    data
  })
}

/*
  ***查询系统参数***
  companyId                                   integer                       公司ID
*/
export const getSysParamsSet = (companyId) => {
  return request({
    url: '/api/system/systemSet/getSysParamsSet',
    method: 'GET',
    params: {
      companyId
    }
  })
}
