import request from '@/utils/api2'

/**
 * ********启动巡检********
*   collectorSn               string          采集器号
    kind                      string          巡检类型                    1.重新下载 2.添加表计
*/
export const startInspect = (data) => {
  return request({
    url: '/api/devManager/collector2/startInspect',
    method: 'POST',
    data
  })
}

/**
 * ********读单表的当前数据********
*   collectorSn               string          采集器号
*/
export const readSingleMeter = (data) => {
  return request({
    url: '/api/devManager/collector2/readSingleMeter',
    method: 'POST',
    data
  })
}
/**
 * ********水表阀控********
*   collectorSn               string          采集器号
*/
export const ValveControl = (data) => {
  return request({
    url: '/api/devManager/collector2/ValveControl',
    method: 'POST',
    data
  })
}
/**
 * ********下载表计地址********
*   collectorSn               string          采集器号
*/
export const DownloadMateAddressParams = (data) => {
  return request({
    url: '/api/devManager/collector2/DownloadMateAddressParams',
    method: 'POST',
    data
  })
}
/**
 * ********设置集中器抄表参数********
*   collectorSn               string          采集器号
*/
export const ConfigMeterReadingParameters = (data) => {
  return request({
    url: '/api/devManager/collector2/ConfigMeterReadingParameters',
    method: 'POST',
    data
  })
}
/**
 * ********读取集中器抄表参数********
 *   collectorSn               string          采集器号
 */
export const QueryMeterReadingParameters = (data) => {
  return request({
    url: '/api/devManager/collector2/QueryMeterReadingParameters',
    method: 'POST',
    data
  })
}

/**
 * ********设置集中器GPRS配置********
*   collectorSn               string          采集器号
*/
export const ConfigGrpsParameters = (data) => {
  return request({
    url: '/api/devManager/collector2/ConfigGrpsParameters',
    method: 'POST',
    data
  })
}
/**
 * ********读取集中器GPRS配置********
*   collectorSn               string          采集器号
*/
export const QueryGrpsParameters = (data) => {
  return request({
    url: '/api/devManager/collector2/QueryGrpsParameters',
    method: 'POST',
    data
  })
}
/**
 * ********读取全部表计日冻结数据********
*   collectorSn               string          采集器号
*/
export const QueryFullMaterDayFrozenData = (data) => {
  return request({
    url: '/api/devManager/collector2/QueryFullMaterDayFrozenData',
    method: 'POST',
    data
  })
}
/**
 * ********读取单表的日冻结数据********
*   collectorSn               string          采集器号
*/
export const QuerySingleMaterDayFrozenData = (data) => {
  return request({
    url: '/api/devManager/collector2/QuerySingleMaterDayFrozenData',
    method: 'POST',
    data
  })
}

export const GetCollectorVerison = (data) => {
  return request({
    url: '/api/devManager/Collector2/GetCollectorVerison',
    method: 'POST',
    data
  })
}
export const GotoBootStartIAP = (data) => {
  return request({
    url: '/api/devManager/Collector2/GotoBootStartIAP',
    method: 'POST',
    data
  })
}
export const UpLoadPackage = (data) => {
  return request({
    url: '/api/devManager/Collector2/UpLoadPackage',
    method: 'POST',
    data,
   
  })
}

export const StrartUpdate = (data) => {
  return request({
    url: '/api/devManager/Collector2/StrartUpdate',
    method: 'POST',
    data
  })
}
