import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementUI from 'element-ui'
import './element.js'
import { myMessage } from '@/utils/resetMessage'
import Bus from '@/utils/bus'
import 'amfe-flexible'  //HTM根元素的font-size 是屏幕宽度/10
//import './utils/rem.js'
import './public.css'  //全局CSS
import '@/assets/iconfont/iconfont.css'  //全局CSS 字体图标CSS
import dayjs from 'dayjs'
import VueBMap from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'
import BaiduMap from 'vue-baidu-map' //2D地图
Vue.use(VueBMap);
Vue.use(BaiduMap, { ak: 'oBKuYB5CkLKnQ77KXZjwOIXKBG5HO9nm' });
VueBMap.initBMapApiLoader({
  ak: 'oBKuYB5CkLKnQ77KXZjwOIXKBG5HO9nm',
  v: '1.0'
});
Vue.use(ElementUI);
Vue.prototype.dayjs = dayjs
Vue.config.productionTip = false
Vue.prototype.$bus = Bus
Vue.prototype.$message = myMessage
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
