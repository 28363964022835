<template>
  <div class="main-page">
    <Menu></Menu>
    <div class="container">
        <router-view />
    </div>
  </div>
</template>
<script>
import Menu from '@/components/menu/waterMenu'
export default {
  components: { Menu },
  data () {
    console.log('当前路由', this.$route)
    return {}
  }
}
</script>

<style lang="scss" scoped>
.container{
  display: flex;
}
</style>
