import request from '@/utils/Api.js'
/*
  ***添加角色***
  userId                  integer               用户Id
  userName                string                用户名称
  logType                 integer               日志类型              1操作 2.系统
  startTime               string                查询开始时间
  endTime                 string                查询结束时间
  content                 string                查询内容
  pageIndex               integer               页码
  pageSize                integer               页大小
*/
export const getSysLogList = (data) => request({
  url: '/api/system/log/getSysLogList',
  method: 'POST',
  data
})

/*
  ***编辑角色***
  userId              integer           用户Id
  userName            string            用户名
  logType             integer           日志类型
  startTime           string            开始时间
  endTime             string            结束时间
  content             string            查询内容
  pageIndex           integer           页大小
  pageSize            integer           页码                             null
*/
export const getSysLogExportData = (data) => request({
  url: '/api/system/log/getSysLogExportData',
  method: 'POST',
  data
})
