import request from '@/utils/Api'
/**
 * ********添加计划任务********
 *  taskName              string                      任务名称
    meterType             integer                     水表类型              1.Lora 2.NB 3.NB分体
    taskDay               integer                     执行日                0表示每天
    taskHour              integer or null             执行时
    taskMinute            integer or null             执行分
    taskStatus            integer                     启动状态                1.启用 2.禁用
    rateType              integer                     定时任务发生频率        1.一次,2.每小时,3.每天,4.每月
*/
export const addTaskPlan = (data) => {
  return request({
    url: '/api/devManager/w1scheduleSet/addTaskPlan',
    method: 'POST',
    data
  })
}

/**
 * ********编辑计划任务********
 *  taskId                integer                     任务ID
 *  taskName              string                      任务名称
    meterType             integer                     水表类型              1.Lora 2.NB 3.NB分体
    taskDay               integer                     执行日                0表示每天
    taskHour              integer or null             执行时
    taskMinute            integer or null             执行分
    taskStatus            integer                     启动状态                1.启用 2.禁用
    rateType              integer                     定时任务发生频率        1.一次,2.每小时,3.每天,4.每月
  */
export const editTaskPlan = (data) => {
  return request({
    url: '/api/devManager/w1scheduleSet/editTaskPlan',
    method: 'POST',
    data
  })
}

/**
 * ********删除计划任务********
*   ids
  */
export const deleteTaskPlans = (ids) => {
  return request({
    url: '/api/devManager/w1scheduleSet/deleteTaskPlans',
    method: 'GET',
    params: {
      ids
    }
  })
}

/**
 * ********查询计划任务********
 * collectorId            integer           集中器ID
   type                   integer           查询类型          1采集器名称 2采集器地址
   condition              string          查询条件
  */
export const getTaskPlan = (id) => {
  return request({
    url: '/api/devManager/w1scheduleSet/getTaskPlan',
    method: 'GET',
    params: {
      id
    }
  })
}

/**
 * ********计划任务列表********
 * hostId         integer         采集器ID
   meterNo         string or null        水表号        模糊查询
  */
export const getTaskPlanList = (data) => {
  return request({
    url: '/api/devManager/w1scheduleSet/getTaskPlanList',
    method: 'POST',
    data
  })
}
