import Vue from 'vue'
import '@/utils/global' // setStorage用的全局变量
import Vuex from 'vuex'
import { getStorage, setStorage } from '@/utils/storage.js'
Vue.use(Vuex)
const store = new Vuex.Store({
  // 全局变量
  state: {
    token: getStorage(Vue.prototype.$storageKey.TokenKey),
    userInfo: getStorage(Vue.prototype.$storageKey.UserInfo),
    roles: getStorage(Vue.prototype.$storageKey.roles)
  },
  // 修改全局变量必须通过mutations中的方法
  // mutations只能采用同步方法
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USERINFO (state, userInfo) {
      state.userInfo = userInfo
    },
    Set_ROLES (state, roles) {
      state.roles = roles
    }
  },
  // 异步方法用actions
  // actions不能直接修改全局变量，需要调用commit方法来触发mutation中的方法
  actions: {
    // async login (context, userInfo) {
    //   const res = await loginAPI(userInfo.userName, userInfo.passWord, userInfo.valliCode)
    //   // console.log(res.data)
    //   if (res.status === 200) {
    //     // Vue.$message.success(res.data.message + 11) ******************************************* 记着其他情况的处理******************
    //     setStorage(Vue.prototype.$storageKey.TokenKey, res.data.data.token)
    //     context.commit('token', res.data.data.token)
    //     setStorage(Vue.prototype.$storageKey.TokenKey, res.data.data.roles)
    //     context.commit('roles', res.data.data.roles)
    //     delete res.data.data.roles
    //     delete res.data.data.token
    //     setStorage(Vue.prototype.$storageKey.UserInfo, JSON.stringify(res.data.data))
    //     context.commit('userInfo', res.data.data)
    //   }
    // },
    logout (context, userInfo = '') {
      localStorage.clear()
      context.commit('token', userInfo)
      context.commit('roles', userInfo)
      context.commit('userInfo', userInfo)
    }
  }
})

export default store
