import request from '@/utils/Api.js'
/*
  ***添加权限***
  pid               integer         父级权限ID        默认0
  rightType         integer         权限类型          1目录 2菜单 3按钮
  rightName         string          权限名称
  order             integer         排序序号          默认0
  icon              string          权限图标
  rightRule         string          权限规则
  path              string          前端路由路径
  component         string          前端组件路径
*/
export const addRight = (data) => {
  const obj = data
  return request({
    url: '/api/system/right/addRight',
    method: 'POST',
    data: obj
  })
}

/*
  ***编辑权限***
  rightId           integer         权限ID
  pid               integer         父级权限ID        默认0
  rightType         integer         权限类型          1目录 2菜单 3按钮
  rightName         string          权限名称
  order             integer         排序序号          默认0
  icon              string          权限图标
  rightRule         string          权限规则
  path              string          前端路由路径
  component         string          前端组件路径
*/
export const editRight = (data) => {
  const obj = data
  return request({
    url: '/api/system/right/editRight',
    method: 'POST',
    data: obj
  })
}

/* ***查询权限*** */
export const getRight = (rightId) => {
  return request({
    url: '/api/system/right/getRight',
    method: 'GET',
    params: {
      rightId: rightId
    }
  })
}

/* ***删除权限*** */
export const deleteRight = (rightId) => {
  return request({
    url: '/api/system/right/deleteRight',
    method: 'GET',
    params: {
      rightId: rightId
    }
  })
}

/*
  ***查询所有权限***
*/
export const getRightList = () => {
  return request({
    url: '/api/system/right/getRightList',
    method: 'POST'
  })
}

/*
  ***查询登录后的权限***
  pageSize          integer             用户ID
  pageIndex         integer             用户名称
*/
export const getLoginRightList = (token) => {
  const header = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return request({
    url: '/api/system/right/getLoginRightList',
    method: 'POST',
    headers: header
  })
}
